import React, {useContext, useEffect} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import qrCodeImage from "../../images/ic_qr_code.png";
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import Column from "@amzn/meridian/column";
import {PickingContext} from "./context/pickingContext";
import {updateAlert} from "../../utils/utils";
import {NOTIFICATION_STRING, PICK_OPERATION} from "../../constants/constants";
import {AppNavigationContext} from "../appNavigation/context/AppNavigationContext";
import {saveAction} from "./actions/pickAndStageActions";

const ScanCartView = (props) => {

    const {navActions} = useContext(AppNavigationContext);
    const {pickingState, pickingActions} = useContext(PickingContext);

    const title = "Place the bag in cart {" + pickingState.activeCart + "}";

    useEffect(() => {
        updateAlert(navActions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCartScan = (cart) => {
        updateAlert(navActions);
        if(cart !== pickingState.activeCart) {
            updateAlert(navActions, "error", <FormattedMessage id={NOTIFICATION_STRING.PICK_INVALID_CART} values={{"cart": cart}}/>);
            return;
        }
        updateAlert(navActions, "success", <FormattedMessage id={NOTIFICATION_STRING.ITEM_PICKED} values={{"itemId": pickingState.items[0].label}} />);
        const item = pickingState.items[0];
        saveAction(PICK_OPERATION.MOVE_ITEM_TO_CART, [pickingState.routeCode, pickingState.routeId, item.type, item.label, pickingState.activeCart]);
        pickingActions.placeItemInCart();
        props.setScanCart(false);
    }

    return (
        <Column>
            <Column spacingInset={"medium"} height="100%" heights="fill">
                <ScanWithCodeView
                    primaryTitle={{
                        title: title
                    }}
                    secondaryTitle={{
                        title: "Scan Cart",
                        size: "Large",
                        type: "Bold"
                    }}
                    image={qrCodeImage} onSubmit={onCartScan} focusLock={true}/>
            </Column>
        </Column>
    );
};

export default injectIntl(ScanCartView);
