import React, {useContext, useEffect} from "react";
import {injectIntl} from "react-intl";
import PickingContextProvider from "./context/pickingContext";
import PickAndStage from "./pickAndStage";
import {AppNavigationContext} from "../appNavigation/context/AppNavigationContext";
import {updateAlert} from "../../utils/utils";

const PickOperation = (props) => {

    const {navState: {helpOptions : onBackClicked}, navActions} = useContext(AppNavigationContext);

    useEffect(() => {
        navActions.resetNavCol();
        navActions.closeOnBack();
        if(onBackClicked) {
            navActions.onBackDefault();
        }
        return () => { updateAlert(navActions) };
        // eslint-disable-next-line
    }, []);

    return (
        <PickingContextProvider>
            <PickAndStage />
        </PickingContextProvider>
    );
}

export default injectIntl(PickOperation);
