/* eslint-disable */
import {DataHandler, Logger} from "@amzn/dolphin-web-framework";
import {OBJECT_STORE, SYNC_TIME, TABLE, THRESHOLD} from "../../constants/constants";
import {getS3Client} from "../../utils/S3Client";
import {
    getBucketName,
    getBucketNameForCB,
    getBucketNameForOB,
    getBucketNameForStow
} from "../../utils/network/BaseURLUtils";
import {cacheContainerPlan, cacheSortZoneMappings} from "../home/action/Actions";

const clearDB = () => {
    indexedDB.deleteDatabase(TABLE.METADATA);
    indexedDB.deleteDatabase(TABLE.CONTAINER_PLAN);
    indexedDB.deleteDatabase(TABLE.SORT_ZONE_MAPPING);
    indexedDB.deleteDatabase(TABLE.STATION_CODES);
};

const uploadBatch = () => {
    return new Promise( (resolve, reject) => {
        getEntries().then(() => {
            resolve();
        }).catch((err) => {
            Logger.log.error("Error in fetching entries from indexeddb :", err);
            reject();
        })
    });
}

const startSync = () => {
    uploadBatch().then(() => {
        Logger.log.info("File will sync after 1 minute!");
        setTimeout(startSync, SYNC_TIME);
    }).catch(() => {
        Logger.log.error("Error in uploadBatch. Retrying!");
        setTimeout(startSync, SYNC_TIME);
    });
}

const saveInformationInDB = (tableName, storeName, storeNameKey, data, operation) => {
    saveToIndexedDb(tableName, storeName, storeNameKey, data)
        .then(() => {
            Logger.log.info(operation + JSON.stringify(data));
        })
        .catch(() => {
            Logger.log.error("Error " + operation + " " + JSON.stringify(data));
        });
}

const saveToIndexedDb = (tableName, objectStoreName, key, object) => {
    return new Promise(function(resolve, reject) {
        const request = indexedDB.open(tableName, 1);

        request.onerror = function(event) {
            Logger.log.error(`Some error occurred in indexedDB ${tableName}:` + request.error);
            reject();
        };

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            db.createObjectStore(objectStoreName, { keyPath: key });
            resolve();
        };

        request.onsuccess = function(event) {
            try {
                const db = event.target.result;
                const objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);
                objectStore.add(object);
                resolve();
            } catch (err) {
                Logger.log.info("Failure adding object to indexedDB: " + tableName);
                reject();
            }
        };
    })
}

const syncTablesToS3 = (table, objectStoreKey, bucketName) => {
    return new Promise(function(resolve, reject){
        const request = indexedDB.open(table, 1);

        request.onerror = function(event) {
            Logger.log.error("Some error occurred in indexedDB: " + table);
            reject();
        };

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            db.createObjectStore(objectStoreKey, { keyPath: objectStoreKey });
            resolve();
        };

        request.onsuccess = function(event) {
            let content = [];
            let keys = [];
            let count = 0;
            const db = event.target.result;
            const objectStore = db.transaction(objectStoreKey, "readwrite").objectStore(objectStoreKey);

            objectStore.openCursor().onsuccess = function(event) {
                let cursor = event.target.result;
                if (cursor && count < THRESHOLD) {
                    content.push(cursor.value.value);
                    count++;
                    keys.push(cursor.key);
                    cursor.continue();
                }
                else {
                    if(content.length !== 0) {
                        const today = new Date();
                        const date = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2);
                        const stationCode = DataHandler.getStationCode();

                        uploadFileToS3(date + '/' + stationCode + '/' + today, content, bucketName)
                            .then(async (data) => {
                                try {
                                    deleteFromIndexedDb(table, objectStoreKey, objectStoreKey, keys);
                                    resolve();
                                } catch (err) {
                                    reject(err);
                                }
                            })
                            .catch((err) => {
                                Logger.log.info('Error uploading object' + err);
                                reject(err);
                            });
                    } else {
                        resolve();
                    }
                }
            };
        };
    });
};

const getEntries = async () => {
    await syncTablesToS3(TABLE.INDUCT, OBJECT_STORE.INDUCT, getBucketName());
    await syncTablesToS3(TABLE.OPEN_BAG, OBJECT_STORE.OPEN_BAG, getBucketNameForOB());
    await syncTablesToS3(TABLE.CLOSE_BAG, OBJECT_STORE.CLOSE_BAG, getBucketNameForCB());
    await syncTablesToS3(TABLE.STOW, OBJECT_STORE.STOW, getBucketNameForStow());
}

const getEntryCount = (table, objectStoreKey) => {
    return new Promise(function(resolve, reject){
        const request = indexedDB.open(table, 1);

        request.onerror = function(event) {
            Logger.log.error("Some error occurred in indexedDB");
            resolve(0);
        };

        request.onupgradeneeded = function (event) {
            const db = event.target.result;
            db.createObjectStore(objectStoreKey, {keyPath: objectStoreKey});
            resolve(0);
        };

        request.onsuccess = function (event) {
            const db = event.target.result;
            const customerObjectStore = db.transaction(objectStoreKey, "readwrite").objectStore(objectStoreKey);
            const countRequest = customerObjectStore.count();
            countRequest.onsuccess = function () {
                resolve(countRequest.result);
            }
        };
    });
}

const countIndexedDb = async () => {
    let result =  await getEntryCount(TABLE.INDUCT, OBJECT_STORE.INDUCT, getBucketName()) +
        await getEntryCount(TABLE.OPEN_BAG, OBJECT_STORE.OPEN_BAG, getBucketNameForOB()) +
        await getEntryCount(TABLE.CLOSE_BAG, OBJECT_STORE.CLOSE_BAG, getBucketNameForCB()) +
        await getEntryCount(TABLE.STOW, OBJECT_STORE.STOW, getBucketNameForStow());
    return result;
}

const uploadFileToS3 = (key, content, bucketName) => {
    const s3 = getS3Client();
    return new Promise((resolve, reject) => {
        s3.putObject({
            Bucket: bucketName,
            Key: key,
            Body: content.join('\n')
        }, (err, data) => {
            if (err) {
                Logger.log.error("Error while uploading file to S3 bucket :", err, data);
                reject(err);
            } else {
                Logger.log.info("File uploaded :", data);
                resolve(data);
            }
        });
    });
}

const getStationCodes = (stationCode) => {
    const tableName = "StationCodes";
    const key = "key";
    const objectStoreName = "stationCodes";
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(tableName, 1);

        request.onerror = function(event) {
            Logger.log.error(`Some error occurred in indexedDB ${tableName}:` + request.error);
            reject();
        };

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            db.createObjectStore(objectStoreName, {keyPath: key});
        };

        request.onsuccess = function(event) {
            try {
                const db = event.target.result;
                const objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);
                const getRequest = objectStore.get(stationCode);

                getRequest.onerror = function(event) {
                    Logger.log.error("Error getting station codes list from IndexedDB");
                    reject();
                }

                getRequest.onsuccess = async function(event) {
                    try {
                        const parentStationCode = getRequest.result.parentStationCode;
                        const stationCodesList = getRequest.result.stationCodesList;
                        Logger.log.info("Fetched station codes list - Parent Station " + parentStationCode + " child list " + JSON.stringify(stationCodesList));
                        resolve([parentStationCode, stationCodesList]);
                    } catch(err) {
                        Logger.log.error("Error fetching station codes from IndexedDB" + err);
                        reject();
                    }
                }
            } catch(err) {
                Logger.log.error("Error fetching station codes from IndexedDB" + err);
                reject();
            }
        };

    })
}

const getContainerPlanForStation = (stationCode, parentStationCode) => {
    const tableName = TABLE.CONTAINER_PLAN;
    const key = OBJECT_STORE.CONTAINER_PLAN;
    const objectStoreName = OBJECT_STORE.CONTAINER_PLAN;
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(tableName, 1);

        request.onerror = function(event) {
            Logger.log.error(`Some error occurred in indexedDB ${tableName}:` + request.error);
            reject();
        };

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            db.createObjectStore(objectStoreName, {keyPath: key});
        }

        request.onsuccess = async function(event) {
            try {
                const db = event.target.result;
                const objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);
                const getRequest = objectStore.get(stationCode);

                getRequest.onerror = function(event) {
                    Logger.log.error("Error getting container plan from IndexedDB");
                    reject();
                }

                getRequest.onsuccess = function(event) {
                    try {
                        const content = getRequest.result.containerPlan;
                        resolve({stationCode: stationCode, parentStationCode: parentStationCode,
                            containerPlan: content});
                    } catch(err) {
                        Logger.log.error("Error creating container plan map for Induct" + err);
                        reject();
                    }
                }

            } catch(err) {
                Logger.log.error("Error extracting container plan from IndexedDB" + err);
                reject();
            }
        }

    })
}

const getSortZoneForStation = (stationCode) => {
    const tableName = TABLE.SORT_ZONE_MAPPING;
    const key = OBJECT_STORE.SORT_ZONE_MAPPING;
    const objectStoreName = OBJECT_STORE.SORT_ZONE_MAPPING;
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(tableName, 1);

        request.onerror = function(event) {
            Logger.log.error(`Some error occurred in indexedDB ${tableName}:` + request.error);
            reject();
        };

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            db.createObjectStore(objectStoreName, {keyPath: key});
        }

        request.onsuccess = async function(event) {
            try {
                const db = event.target.result;
                const objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);
                const getRequest = objectStore.get(stationCode);

                getRequest.onerror = function(event) {
                    Logger.log.error("Error getting container plan from IndexedDB");
                    reject();
                }

                getRequest.onsuccess = function(event) {
                    try {
                        const content = getRequest.result.sortZoneMapping;
                        resolve(content);
                    } catch(err) {
                        Logger.log.error("Error creating sortZoneMapping" + err);
                        reject();
                    }
                }

            } catch(err) {
                Logger.log.error("Error extracting sortZoneMapping from IndexedDB" + err);
                reject();
            }
        }

    })
}

const getSortZoneMappings = async (stationCode, tableName, objectStoreName, key) => {
    let parentStationCode;
    let stationCodesList;

    await getStationCodes(stationCode)
        .then((stationList) => {
            parentStationCode = stationList[0];
            stationCodesList = stationList[1];
        });
    return new Promise(async (resolve, reject) => {
        const request = indexedDB.open(tableName, 1);

        request.onerror = function(event) {
            Logger.log.error(`Some error occurred in indexedDB ${tableName}:` + request.error);
            reject();
        };

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            db.createObjectStore(objectStoreName, {keyPath: key});
        }

        request.onsuccess = async function(event) {
            try {
                let sortZoneMappings = [];
                for(let i = 0; i < stationCodesList.length; i++) {
                    await getSortZoneForStation(stationCodesList[i])
                        .then((sortZoneMapping) => {
                            sortZoneMappings.push({stationCode: stationCodesList[i], sortZoneMapping: sortZoneMapping});
                        })
                }

                const map = await cacheSortZoneMappings(sortZoneMappings);
                resolve(map);

            } catch(err) {
                Logger.log.error("Error extracting container plan from IndexedDB" + err);
                reject();
            }
        }
    });
}

const getContainerPlanMap = async (stationCode, tableName, objectStoreName, key) => {
    let parentStationCode;
    let stationCodesList;

    await getStationCodes(stationCode)
        .then((stationList) => {
            parentStationCode = stationList[0];
            stationCodesList = stationList[1];
        });
    Logger.log.info("Fetched station codes from DB " + JSON.stringify(stationCodesList));

    return new Promise(async (resolve, reject) => {
        const request = indexedDB.open(tableName, 1);

        request.onerror = function(event) {
            Logger.log.error(`Some error occurred in indexedDB ${tableName}:` + request.error);
            reject();
        };

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            db.createObjectStore(objectStoreName, {keyPath: key});
        }

        request.onsuccess = async function(event) {
            try {
                let containerPlanList = [];
                for(let i = 0; i < stationCodesList.length; i++) {
                    await getContainerPlanForStation(stationCodesList[i], parentStationCode)
                        .then((containerPlan) => {
                            containerPlanList.push(containerPlan);
                        })
                }
                Logger.log.info("Container Plans List " + containerPlanList.length);
                const map = await cacheContainerPlan(containerPlanList);
                Logger.log.info("Reduced Plans " + JSON.stringify(Object.keys(map).slice(0, 10)) + " Length " + JSON.stringify(Object.keys(map).length));
                resolve(map);

            } catch(err) {
                Logger.log.error("Error extracting container plan from IndexedDB" + err);
                reject();
            }
        }
    });
}

const deleteFromIndexedDb = (tableName, objectStoreName, key, records) => {
    const request = indexedDB.open(tableName, 1);

    request.onerror = function(event) {
        Logger.log.error(`Some error occurred in indexedDB ${tableName}:` + request.error);
    }

    request.onupgradeneeded = function(event) {
        const db = event.target.result;
        db.createObjectStore(objectStoreName, {keyPath: key});
    }

    request.onsuccess = function(event) {
        try {
            const db = event.target.result;
            const objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);
            records.forEach(function(key) {
                objectStore.delete(key);
            });
        } catch (err) {
            Logger.log.error("Error deleting induct scan results from IndexedDB:" + err);
        }
    }
}

export {saveToIndexedDb, getEntries, countIndexedDb, getContainerPlanMap, getSortZoneMappings, saveInformationInDB, startSync, clearDB}