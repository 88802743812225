import React, {useContext, useEffect} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import PrePick from "./prePick";
import Picking from "./picking";
import Staging from "./staging";
import {PickingContext} from "./context/pickingContext";
import {PICKING_STATUS} from "./reducer/PickingContextReducer";
import {getPickContextFromIndexedDB} from "./actions/pickAndStageActions";
import {updateAlert} from "../../utils/utils";
import {NOTIFICATION_STRING} from "../../constants/constants";
import {AppNavigationContext} from "../appNavigation/context/AppNavigationContext";
import EndPicking from "./endPicking";


const PickAndStage = (props) => {
    const {navActions} = useContext(AppNavigationContext);
    const {pickingState, pickingActions} = useContext(PickingContext);

    useEffect(() => {
        navActions.showLoader();
        getPickContextFromIndexedDB().then(context => {
            if(context && context.routeCode) {
                pickingActions.setPickingContext(context);
                updateAlert(navActions, "success", <FormattedMessage id={NOTIFICATION_STRING.ALREADY_ASSIGNED_ROUTE} values={{"routeCode": context.routeCode}} />);
            }
        }).catch(() => {
            updateAlert(navActions, "error", <FormattedMessage id={NOTIFICATION_STRING.UNEXPECTED_ERROR} />);
        }).finally(() => {
            navActions.hideLoader();
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    switch (pickingState.status) {
        case PICKING_STATUS.PRE_PICK:
            return <PrePick />;
        case PICKING_STATUS.PICKING:
            return <Picking />;
        case PICKING_STATUS.STAGING:
            return <Staging />;
        case PICKING_STATUS.END_ROUTE:
            return <EndPicking />
        default:
            return <></>;
    }
}

export default injectIntl(PickAndStage);
