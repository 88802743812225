/* eslint-disable */
import AmazonS3URI from "amazon-s3-uri";
import {ApplicationActions} from "../../containerPlan/action/ApplicationActions";
import {DataHandler, Logger} from "@amzn/dolphin-web-framework";
import {getS3Client} from "../../../utils/S3Client";
import {DELIMITER, OBJECT_STORE, S3_PREFIX, SUFFIX, TABLE, XPT} from "../../../constants/constants";
import {getBucketNameForCP, getDates} from "../../../utils/network/BaseURLUtils";
import {getContainerPlanMap, getSortZoneMappings, saveToIndexedDb} from "../../network/IndexedDbUtility";

const loadConfig = (s3, bucket, key) => {
    return new Promise((resolve, reject) => {
        s3.getObject({
            Bucket: bucket,
            Key: key
        }, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
}

const loadContainerPlan = (S3, uri, stationCode)  => {
    const URI = AmazonS3URI(uri);
    loadConfig(S3, URI.bucket, URI.key)
        .then((data) => {
            try {
                const object = {
                    "stationCode": stationCode,
                    "containerPlan": data.Body.toString('utf-8')
                };
                saveToIndexedDb(TABLE.CONTAINER_PLAN, OBJECT_STORE.CONTAINER_PLAN, OBJECT_STORE.CONTAINER_PLAN, object)
                    .then(() => {
                        Logger.log.info("Cached container plan for station " + stationCode);
                    });
            } catch (err) {
                Logger.log.error(`Error creating container plan object for ${stationCode}` + err);
            }
        })
        .catch((err) => {
            Logger.log.error(`Error fetching container plan for station ${stationCode}:` + err);
        });
}

const loadSortZoneMappings = async (S3, URI, stationCode)  => {
    await loadConfig(S3, URI.bucket, URI.key)
        .then((data) => {
            try {
                const object = {
                    "stationCode": stationCode,
                    "sortZoneMapping": data.Body.toString('utf-8')
                };
                saveToIndexedDb(TABLE.SORT_ZONE_MAPPING, OBJECT_STORE.SORT_ZONE_MAPPING, OBJECT_STORE.SORT_ZONE_MAPPING, object)
                    .then(() => {
                        Logger.log.info(`Downloaded sortZoneMapping for station ${stationCode}`);
                    });
            } catch (err) {
                Logger.log.error(`Error loading sortZoneMapping object for ${stationCode}` + err);
            }
        })
        .catch((err) => {
            Logger.log.error(`Error fetching sortZoneMapping for station ${stationCode}:` + err);
        });
}

const fetchSortZoneMapping = async (stationCode, bucketName, date, S3) => {
    let sortZoneUri = S3_PREFIX + bucketName + DELIMITER + date + DELIMITER +
        stationCode + DELIMITER + stationCode + SUFFIX.SORT_ZONE_MAPPING;
    const SORT_ZONE_URI = AmazonS3URI(sortZoneUri);

    await loadSortZoneMappings(S3, SORT_ZONE_URI, stationCode);
};

const loadAllSortZoneMappings = async (stationCodes, date, S3) => {
    for (let i = 0; i < stationCodes.length; i++) {
        await fetchSortZoneMapping(stationCodes[i], getBucketNameForCP(), date, S3);
    }
};

const loadMetadataAndOtherContainerPlans = async (stationCode, content, date, S3) => {
    const bucketName = getBucketNameForCP();
    const parsedContent = JSON.parse(content);
    let metadataUri;

    try {
        if(XPT === parsedContent.stationType) {
            metadataUri = S3_PREFIX + bucketName + DELIMITER + date + DELIMITER +
                parsedContent.parentStation + DELIMITER + parsedContent.parentStation + SUFFIX.METADATA;
        } else {
            metadataUri = S3_PREFIX + bucketName + DELIMITER + date + DELIMITER +
                stationCode + DELIMITER + stationCode + SUFFIX.METADATA;
        }

        const METADATA_URI = AmazonS3URI(metadataUri);
        let metadata;
        let parsedMetadata;
        let cycleMap = {};

        // fetching metadata from S3 and caching it in IndexedDB
        await loadConfig(S3, METADATA_URI.bucket, METADATA_URI.key)
            .then(async (data) => {
                try {
                    metadata = data.Body.toString('utf-8');
                    parsedMetadata = JSON.parse(metadata);
                    const object = {
                        "stationCode": parsedMetadata.ParentStation.stationCode,
                        "metadata": metadata
                    };
                    cycleMap[parsedMetadata.ParentStation.stationCode] = parsedMetadata.ParentStation.cycle;
                    await saveToIndexedDb(TABLE.METADATA, OBJECT_STORE.METADATA, OBJECT_STORE.METADATA, object)
                        .then(() => {
                            Logger.log.info("Metadata successfully cached in IndexedDB: {} " + JSON.stringify(object));
                        });
                } catch (err) {
                    Logger.log.error("Error creating metadata object: " + err);
                }
            })
            .catch((err) => {
                Logger.log.error("Error fetching metadata from S3: " + err);
            })

        // if parsedMetadata is null or undefined
        // we can't carry on with further operations
        if(null == parsedMetadata) {
            return;
        }

        let fetchedStationCodes = [stationCode];

        // fetching and caching container plan for parent station if logged in station is an XPT
        if(stationCode !== parsedMetadata.ParentStation.stationCode) {
            const parentStationCode = parsedMetadata.ParentStation.stationCode;
            const parentStationUri = S3_PREFIX + bucketName + DELIMITER + date + DELIMITER +
                parentStationCode + DELIMITER + parentStationCode + SUFFIX.CONTAINER_PLAN;
            await loadContainerPlan(S3, parentStationUri, parentStationCode);
            fetchedStationCodes.push(parsedMetadata.ParentStation.stationCode);
        }

        // fetching container plans for all the XPTs and caching them in IndexedDB
        const xptArray = parsedMetadata.XPTStations;
        for(let i = 0; i < xptArray.length; i++) {
            if(stationCode === xptArray[i].stationCode) {
                // eslint-disable-line no-loop-func
                cycleMap[xptArray[i].stationCode] = xptArray[i].cycle;
                continue;
            }
            cycleMap[xptArray[i].stationCode] = xptArray[i].cycle;
            const xptUri = S3_PREFIX + bucketName + DELIMITER + date + DELIMITER +
                xptArray[i].stationCode + DELIMITER + xptArray[i].stationCode + SUFFIX.CONTAINER_PLAN;
            await loadContainerPlan(S3, xptUri, xptArray[i].stationCode);
            fetchedStationCodes.push(xptArray[i].stationCode);
        }

        const object = {
            "key": stationCode,
            "stationCodesList": fetchedStationCodes,
            "parentStationCode": parsedMetadata.ParentStation.stationCode
        };

        await loadAllSortZoneMappings(fetchedStationCodes, date, S3);

        await saveToIndexedDb("StationCodes", "stationCodes", "key", object)
            .then(() => {
                Logger.log.info("Station codes list successfully cached in IndexedDB: {}" + JSON.stringify(object));
            });
        Logger.log.info("Cycle Map Returned {}" + JSON.stringify(cycleMap));
        return cycleMap;
    } catch (err) {
        Logger.log.error("Error while trying to fetch and store metadata and additional container plans " + err);
    }
}

const load = async (dispatch) => {
    dispatch({type: ApplicationActions.LOADING_MAP});
    const bucketName = getBucketNameForCP();
    const S3 = getS3Client();
    const stationCode = DataHandler.getStationCode();
    const dateArray = getDates();
    let containerPlanFetched = false;
    let cycleMap;

    for(let index = 0; index < dateArray.length; index++) {
        if(containerPlanFetched) {
            break;
        }

        try {
            const uri = S3_PREFIX + bucketName + DELIMITER + dateArray[index] + DELIMITER + stationCode
                + DELIMITER + stationCode + SUFFIX.CONTAINER_PLAN;
            const URI = AmazonS3URI(uri);

            await loadConfig(S3, URI.bucket, URI.key)
                .then(async (data) => {
                    containerPlanFetched = true;
                    try {
                        const content = data.Body.toString('utf-8');
                        const object = {
                            "stationCode": stationCode,
                            "containerPlan": content
                        };
                        await saveToIndexedDb(TABLE.CONTAINER_PLAN, OBJECT_STORE.CONTAINER_PLAN,
                            OBJECT_STORE.CONTAINER_PLAN, object);
                        cycleMap = await loadMetadataAndOtherContainerPlans(stationCode, content, dateArray[index], S3);
                    } catch(err) {
                        Logger.log.info("Error loading config from S3 " + err);
                    }
                })
                .catch((err) => {
                    Logger.log.error("Error loading container plan from S3 " + err);
                });
        } catch (err) {
            //for outermost try block
            Logger.log.error("Invalid S3 URI " + err);
            dispatch({type: ApplicationActions.ERROR_MAP});
        }
    }

    // creating a map for Induct using container plan stored in IndexedDB
    let map = await getContainerPlanMap(stationCode, TABLE.CONTAINER_PLAN, OBJECT_STORE.CONTAINER_PLAN, OBJECT_STORE.CONTAINER_PLAN)
    Logger.log.info("Final information map {}" + JSON.stringify(map));
    Logger.log.info("Final cycle map {}" + JSON.stringify(cycleMap));
    await getSortZoneMappings(stationCode, TABLE.SORT_ZONE_MAPPING, OBJECT_STORE.SORT_ZONE_MAPPING, OBJECT_STORE.SORT_ZONE_MAPPING)
        .then((sortZoneMappings) => {
            dispatch({
                type: ApplicationActions.UPDATE_MAP,
                data: {map: map, cycle: cycleMap, sortZoneMappings: sortZoneMappings}
            })
        })
        .catch(() => {
            dispatch({type: ApplicationActions.ERROR_MAP});
        })
}

export {loadConfig, load};