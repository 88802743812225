import React, {Component} from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import {Routes} from "../utils/Routes";
import ScanPackage from "../components/scanPackage/scanPackageView";
import ContainerPlanContextProvider from "./containerPlan/context/ContainerPlanContext";
import AppNavigationContextProvider from "./appNavigation/context/AppNavigationContext";
import AppNavigation from "./appNavigation/AppNavigation";
import Column from "@amzn/meridian/column";
import "@amzn/dolphin-uiweb-framework/dist/index.css";
import {Logger, MeshClient} from "@amzn/dolphin-web-framework";
import {startSync, clearDB} from "./network/IndexedDbUtility";
import Home from "./home/homeView";
import StowOptionsView from "../components/stow/stowOptionsView";
import OpenBagView from "./stow/stowReadiness/openBagView";
import CloseBagView from "./stow/stowReadiness/closeBagView";
import StowPackageView from "./stow/stowPackage/stowPackageView";
import PickOperation from "./pickStage/pickOperation";

class App extends Component {

    async componentWillMount() {
        startSync();
        clearDB();
        Logger.log.info("componentWillMount");
        await MeshClient.connectWebSocket();
    }

    async componentWillUnmount() {
        Logger.log.info("componentWillUnmount");
        await MeshClient.closeWebSocketConnection();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname ||
            this.props.location.search !== prevProps.location.search) {
            Logger.log.info("Route Change", {previousPath: prevProps.location, currentPath: this.props.location});
        }
    }

    render() {
        return (
            <AppNavigationContextProvider history={this.props.history}>
                <ContainerPlanContextProvider>
                    <Column height="100%" spacing="none">
                        <AppNavigation/>
                        <div className="content">
                            <Switch>
                                <Route exact path={Routes.SCAN_PACKAGE} component={ScanPackage} />
                                <Route exact path={Routes.HOME} component={Home} />
                                <Route exact path={Routes.STOW_OPTIONS} component={StowOptionsView} />
                                <Route exact path={Routes.STOW_READINESS} component={OpenBagView} />
                                <Route exact path={Routes.CLOSE_BAG} component={CloseBagView} />
                                <Route exact path={Routes.STOW_PACKAGE} component={StowPackageView} />
                                <Route exact path={Routes.PICK_OPERATION} component={PickOperation} />
                            </Switch>
                        </div>
                    </Column>
                </ContainerPlanContextProvider>
            </AppNavigationContextProvider>
        );
    }
}

export default withRouter(App);
