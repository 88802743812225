import React, {useContext, useEffect} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import {DetailsCardView, ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import {PickingContext} from "./context/pickingContext";
import Row from "@amzn/meridian/row";
import {AppNavigationContext} from "../appNavigation/context/AppNavigationContext";
import {NOTIFICATION_STRING, PICK_OPERATION} from "../../constants/constants";
import {PICKING_STATUS} from "./reducer/PickingContextReducer";
import {updateAlert} from "../../utils/utils";
import {saveAction} from "./actions/pickAndStageActions";

const ScanBagView = (props) => {

    const {navActions} = useContext(AppNavigationContext);
    const {pickingState, pickingActions} = useContext(PickingContext);

    const getHelpOptions = () => {
        return {
            pageTitle: "Pick and Stage help center",
            list: [
                {
                    title: "Missing Item",
                    onClick: () => { moveItemToProblemSolve("MISSING") }
                },
                {
                    title: "Damaged Item",
                    onClick: () => { moveItemToProblemSolve("DAMAGED") }
                },
                {
                    title: "Stage cart",
                    onClick: stageCartHelpCenter
                }
            ]
        }
    };

    useEffect(() => {
        navActions.setupHelp(true, getHelpOptions())
        return () => {navActions.setupHelp(true, {list: []})}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickingState.items]);

    const moveItemToProblemSolve = (reason) => {
        const item = pickingState.items[0];
        saveAction(PICK_OPERATION.REPORT_ITEM, [pickingState.routeCode, pickingState.routeId, item.type, item.label, reason])
        pickingActions.moveItemToProblemSolve();
        navActions.closeHelp();
        updateAlert(navActions, "success", <FormattedMessage id={NOTIFICATION_STRING.REPORT_ITEM} values={{"itemId": item.label, "report": reason}} />);
    }

    const stageCartHelpCenter = () => {
        if(hasItemsInCart()) {
            pickingActions.setStatus(PICKING_STATUS.STAGING);
        } else {
            updateAlert(navActions, "error", <FormattedMessage id={NOTIFICATION_STRING.STAGING_EMPTY_CART_ERROR} />);
        }
        navActions.closeHelp();
    }

    const hasItemsInCart = () => {
        const pickedItemsList = pickingState.pickedItems;
        const activeCart = pickingState.activeCart;
        return activeCart && pickedItemsList[activeCart] && pickedItemsList[activeCart].length;
    }

    const data = [
        {
            description: "Type",
            title: pickingState.items.length ? pickingState.items[0].type : ""
        },{
            description: "Aisle",
            title: pickingState.items.length ? pickingState.items[0].sortLocation : ""
        }
    ]

    const metadata = {
        title: pickingState.items && pickingState.items.length ? pickingState.items[0].label : "",
        backgroundColor: "#DCB700",
        fontColor: "black"
    }

    const onItemScan = (packageId) => {
        updateAlert(navActions);
        if(packageId !== pickingState.items[0].label) {
            updateAlert(navActions, "error", <FormattedMessage id={NOTIFICATION_STRING.PICK_INCORRECT_ITEM} values={{"itemId": packageId}} /> );
            return;
        }
        props.setScanCart(true);
    }


    return (
        <Column>
            <Row widths="fill">
                <ScanWithCodeView
                    primaryTitle={{
                        title: "Scan item",
                        size: "Large",
                        type: "Bold"
                    }}
                    onSubmit={onItemScan} focusLock={true}/>
            </Row>
            <Row alignmentHorizontal="center" widths="90%">
                <DetailsCardView snapshots={data} headerFontSize="Large" display="Row" metadata={metadata} />
            </Row>
        </Column>
    );
}

export default injectIntl(ScanBagView);
