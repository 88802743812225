import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import {ScanWithInputView} from "@amzn/dolphin-uiweb-framework";
import {Logger} from "@amzn/dolphin-web-framework";
import {PickingContext} from "./context/pickingContext";
import {assignRoute, saveAction} from "./actions/pickAndStageActions";
import {AppNavigationContext} from "../appNavigation/context/AppNavigationContext";
import {PICK_OPERATION} from "../../constants/constants";
import {PICKING_STATUS} from "./reducer/PickingContextReducer";
import {updateAlert} from "../../utils/utils";

const InputRoute = (props) => {

    const {navActions} = useContext(AppNavigationContext);
    const {pickingActions} = useContext(PickingContext);

    const onRouteCodeSubmit = (routeCode) => {
        updateAlert(navActions);
        navActions.showLoader();
        assignRoute(routeCode).then(context => {
            saveAction(PICK_OPERATION.ASSIGN_ROUTE, [context.routeCode, context.routeId]);
            context["status"] = PICKING_STATUS.PRE_PICK;
            pickingActions.setPickingContext(context);
        }).catch(err => {
            Logger.log.error("Error occurred on route code submit", err);
            updateAlert(navActions, "error", err);
        }).finally(() => {
            navActions.hideLoader();
        })
    }

    return (
        <ScanWithInputView title="Get route code from manager" description="Enter route code" onSubmit={onRouteCodeSubmit} />
    );
}

export default injectIntl(InputRoute);
