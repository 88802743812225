import React, {useContext, useEffect} from "react";
import {injectIntl} from "react-intl";
import {AppNavigationContext} from "../appNavigation/context/AppNavigationContext";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {Routes} from '../../utils/Routes';
import {updateAlert} from "../../utils/utils";
import {ContainerPlanContext} from "../containerPlan/context/ContainerPlanContext";

const StowOptionsView = (props) => {

    // eslint-disable-next-line
    const {stateContainerPlan} = useContext(ContainerPlanContext);
    // eslint-disable-next-line
    const {navState: {helpOptions : {open: helpOpen}, onBackClicked}, navActions} = useContext(AppNavigationContext);

    const stowReadinessOperation = () => {
        props.history.push(Routes.STOW_READINESS);
        updateAlert(navActions);
    }

    const stowPackageOperation = () => {
        props.history.push(Routes.STOW_PACKAGE);
        updateAlert(navActions);
    }

    const closeBagOperation = () => {
        props.history.push(Routes.CLOSE_BAG);
        updateAlert(navActions);
    }

    const getTasks = () => {
        return [
            {
                title: "Stow Readiness",
                description: "Open sort bags before induct to make bags ready for sortation",
                onClick : stowReadinessOperation
            },
            {
                title: "Stow Packages",
                description: "Stow packages to allocated sort zone locations in aisles",
                onClick : stowPackageOperation
            }
        ]
    };

    const getHelpOptions = (callbacks, pageTitle, pageText) => {
        return {
            pageTitle: pageTitle,
            list: [{
                title: pageText,
                onClick: () => {
                    callbacks.forEach(callback => callback())
                }
            }]
        }
    };

    useEffect(() => {
        navActions.resetNavCol();
        navActions.closeOnBack();
        if(onBackClicked) {
            navActions.onBackDefault();
        }

        const callbacks = [
            closeBagOperation,
            navActions.closeHelpOption
        ]
        navActions.setupHelp(true, getHelpOptions(callbacks, "", "Close Bag"))
        // eslint-disable-next-line
    }, []);

    return (
        <div className="scrollList">
            <TaskView pageTitle="Select a sortation task"
                      tasks={getTasks()}/>
        </div>
    );
}

export default injectIntl(StowOptionsView);