import AWS from "aws-sdk";
import {getIdentityPoolId, getIdentityPoolIdRegion} from "./network/BaseURLUtils";

const getS3Client = () => {

        AWS.config.update({
            region: getIdentityPoolIdRegion(),
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: getIdentityPoolId()
            }),
            maxRetries: 3
        });

    return new AWS.S3();
}

export {getS3Client}