let historyReplaceEvent = false;
let helpCloseInterceptor = false
let prevBackPressedListener = undefined;
let interceptBackButtonReason = undefined;

/**
 * Listener to history events to call appropriate callbacks
 */
window.addEventListener('popstate', function (event) {
    if (!historyReplaceEvent) {
        if (helpCloseInterceptor) {
            prevBackPressedListener && prevBackPressedListener(event);
            helpCloseInterceptor = false;
        } else if (interceptBackButtonReason) {
            window.history.pushState({noBack: true}, '');
            prevBackPressedListener && prevBackPressedListener(event);
        }
    } else {
        historyReplaceEvent = false;
    }
});

export function setBackPressedEventListener(listener) {
    prevBackPressedListener = listener;
}

export function clearBackPressedEventListener() {
    prevBackPressedListener = undefined;
}

/**
 * Don't listen on history replace events they are not
 * considered to be back events just however still activate
 * popstate event.
 */
export function historyReplace() {
    historyReplaceEvent = true;
}

/**
 * Help Option close is handled separately from
 * page navigation behaviours
 */
export function interceptHelpClose() {
    helpCloseInterceptor = true;
    window.history.pushState({helpOpen: true}, '');
}

export function interceptBackButton(reason) {
    interceptBackButtonReason = reason;
    window.history.pushState({noBack: true}, '');
}

export function interceptBackButtonStop() {
    interceptBackButtonReason = undefined;
}