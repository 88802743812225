import React, {useContext, useEffect} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import qrCodeImage from "../../images/ic_qr_code.png";
import {AppNavigationContext} from "../appNavigation/context/AppNavigationContext";
import {PickingContext} from "./context/pickingContext";
import {updateAlert} from "../../utils/utils";
import {NOTIFICATION_STRING, PICK_OPERATION, STRING_CONSTANT} from "../../constants/constants";
import {saveAction} from "./actions/pickAndStageActions";
import {PICKING_STATUS} from "./reducer/PickingContextReducer";

const Staging = (props) => {

    const {navActions} = useContext(AppNavigationContext);
    const {pickingState, pickingActions} = useContext(PickingContext);

    useEffect(() => {
        if(!pickingState.pickedItems[pickingState.activeCart] && pickingState.items.length === 0) {
            saveAction(PICK_OPERATION.END_ROUTE, []);
            pickingActions.setStatus(PICKING_STATUS.END_ROUTE);
        }
        // eslint-disable-next-line
    }, []);

    const stageCart = (stagingLocationScannable) => {
        updateAlert(navActions);
        const cartToBeStaged = pickingState.activeCart;
        saveAction(PICK_OPERATION.STAGE_CART, [pickingState.routeCode, pickingState.routeId, cartToBeStaged, pickingState.stagingLocation, stagingLocationScannable]);
        if(pickingState.items.length === 0) {
            saveAction(PICK_OPERATION.END_ROUTE, [pickingState.routeCode, pickingState.routeId]);
        }
        pickingActions.stageCart();
        updateAlert(navActions, "success", <FormattedMessage id={NOTIFICATION_STRING.CART_STAGED_TO_LOCATION} values={{"cart": cartToBeStaged}} />);
    }

    return (
        <Column>
            <ScanWithCodeView
                primaryTitle={{
                    title: STRING_CONSTANT.STAGE_CART
                }}
                secondaryTitle={{
                    title: pickingState.stagingLocation ? STRING_CONSTANT.SCAN_STAGING_LOCATION + pickingState.stagingLocation : STRING_CONSTANT.SCAN_AVAILABLE_STAGING_LOCATION,
                    size: "Large",
                    type: "Bold"
                }}
                image={qrCodeImage} onSubmit={stageCart} focusLock={true}/>
        </Column>
    );
}

export default injectIntl(Staging);
