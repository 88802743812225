import React, {createContext, useReducer} from "react";
import {
    DEFAULT_VALUE,
    PICKING_STATUS,
    PICK_CONTEXT_ACTIONS,
    PickingContextReducer
} from "../reducer/PickingContextReducer";
import {savePickContextInIndexedDB} from "../utils/pickDbUtils";


export const PickingContext = createContext();

const PickingContextProvider = (props) => {
    const [state, dispatch] = useReducer(PickingContextReducer, DEFAULT_VALUE);

    const setStatus = (status) => {
        savePickContextInIndexedDB({...state, status: status});
        dispatch({type: PICK_CONTEXT_ACTIONS.SET_STATUS, value: status});
    }

    const setPickingContext = (context) => {
        savePickContextInIndexedDB(context);
        dispatch({type: PICK_CONTEXT_ACTIONS.SET_CONTEXT, value: context});
    }

    const attachCart = (cart) => {
        savePickContextInIndexedDB({...state, activeCart: cart});
        dispatch({type: PICK_CONTEXT_ACTIONS.ADD_CART_TO_ROUTE, value: cart});
    }

    const placeItemInCart = () => {
        const items = [...state.items];
        const currentItem = items.splice(0, 1);
        const pickedItems = {...state.pickedItems};
        pickedItems[state.activeCart] = pickedItems[state.activeCart] || [];
        pickedItems[state.activeCart].push(...currentItem);

        savePickContextInIndexedDB({...state, items: items, pickedItems: pickedItems});
        dispatch({type: PICK_CONTEXT_ACTIONS.MOVE_ITEM_TO_CART, items: items, pickedItems: pickedItems});
    }

    const stageCart = () => {
        savePickContextInIndexedDB({...state, activeCart: null, status: PICKING_STATUS.END_ROUTE});
        dispatch({type: PICK_CONTEXT_ACTIONS.STAGE_CART});
    }

    const moveItemToProblemSolve = () => {
        const items = [...state.items];
        const pickedItem = items.splice(0, 1);
        savePickContextInIndexedDB({...state, items: items, problemSolveItems: [...state.problemSolveItems, ...pickedItem]});
        dispatch({type: PICK_CONTEXT_ACTIONS.REPORT_ITEM, items: items, problemSolveItems: [...state.problemSolveItems, ...pickedItem]});
    }

    const resetPickingContext = () => {
        savePickContextInIndexedDB(DEFAULT_VALUE);
        dispatch({type: PICK_CONTEXT_ACTIONS.RESET_CONTEXT});
    }

    return (
        <PickingContext.Provider value={{
            pickingState: state,
            pickingActions: {
                attachCart,
                placeItemInCart,
                stageCart,
                setPickingContext,
                setStatus,
                moveItemToProblemSolve,
                resetPickingContext,
            }
        }}>
            {props.children}
        </PickingContext.Provider>
    )
}

export default PickingContextProvider;
