import React, {useContext} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {AppNavigationContext} from "../appNavigation/context/AppNavigationContext";
import {PickingContext} from "./context/pickingContext";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import {NOTIFICATION_STRING, STRING_CONSTANT} from "../../constants/constants";
import {deletePickDB, uploadLogsToS3} from "./actions/pickAndStageActions";
import {PICKING_STATUS} from "./reducer/PickingContextReducer";
import {updateAlert} from "../../utils/utils";

const EndPicking = (props) => {
    const {navActions} = useContext(AppNavigationContext);
    const {pickingState, pickingActions} = useContext(PickingContext);

    const endPicking = () => {
        if(pickingState.items.length) {
            pickingActions.setStatus(PICKING_STATUS.PICKING);
        } else {
            navActions.showLoader();
            uploadLogsToS3(pickingState.routeCode).then(() => {
                deletePickDB();
                pickingActions.resetPickingContext();
                updateAlert(navActions, "success", <FormattedMessage id={NOTIFICATION_STRING.PICKING_COMPLETED} />);
            }).catch(() => {
                updateAlert(navActions, "error", <FormattedMessage id={NOTIFICATION_STRING.PICK_ERROR} />);
            }).finally(() => {
                navActions.hideLoader();
            });
        }
    }

    return (
        <Column>
            <Column>
                <Text type={"h500"} alignment={"center"}>{pickingState.items.length ? STRING_CONSTANT.PICK_REMAINING_PACKAGE : STRING_CONSTANT.PICK_COMPLETED}</Text>
                <Text type={"b300"} alignment={"center"}>{pickingState.items.length ? STRING_CONSTANT.SCAN_NEW_CART : STRING_CONSTANT.PICK_NEW_ROUTE}</Text>
            </Column>
            <Column className="footer">
                <Button onClick={endPicking}>{pickingState.items.length ? STRING_CONSTANT.CONTINUE : STRING_CONSTANT.END_PICKING}</Button>
            </Column>
        </Column>
    );

}

export default injectIntl(EndPicking);
