import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import "@amzn/meridian-tokens/base/font/amazon-ember.css";
import {HashRouter} from "react-router-dom";
import {addLocaleData, IntlProvider} from "react-intl";
import {DataHandler, Logger} from "@amzn/dolphin-web-framework";
import {getLoggerUrl, getStage} from "./utils/network/BaseURLUtils";
import de from 'react-intl/locale-data/de'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import fr from 'react-intl/locale-data/fr'
import hi from 'react-intl/locale-data/hi'
import it from 'react-intl/locale-data/it'
import ja from 'react-intl/locale-data/ja'
import pt from 'react-intl/locale-data/pt'
import ro from 'react-intl/locale-data/ro'
import ar from 'react-intl/locale-data/ar'
import nl from 'react-intl/locale-data/nl'
import tr from 'react-intl/locale-data/tr'
import {getLocale, getMessages} from "./handler/LocaleHandler";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

addLocaleData(de);
addLocaleData(en);
addLocaleData(es);
addLocaleData(fr);
addLocaleData(hi);
addLocaleData(it);
addLocaleData(ja);
addLocaleData(pt);
addLocaleData(ro);
addLocaleData(ar);
addLocaleData(nl);
addLocaleData(tr);

Logger.setLogger(getLoggerUrl(), getStage());
DataHandler.parseSearchParams();

ReactDOM.render(
    <IntlProvider locale={getLocale()} messages={getMessages()}>
        <HashRouter>
            <App/>
        </HashRouter>
    </IntlProvider>,
    document.getElementById('root')
);

/**
 * change the following register() to unregister()
 * to disable service worker
 */
serviceWorkerRegistration.register();
