import beep from "../audio/res/beep.ogg";
import beep_error from "../audio/res/beep_error.ogg";

function initializeSoundObject(resource) {
    let audio = new Audio(resource);
    audio.preload = 'auto';
    return audio
}

let beep_sound = initializeSoundObject(beep);
let beep_error_sound = initializeSoundObject(beep_error);

export const SoundType = {
    BEEP: beep_sound,
    BEEP_ERROR: beep_error_sound,
};