export const PICKING_STATUS = {
    PRE_PICK: "PRE_PICK",
    PICKING: "PICKING",
    STAGING: "STAGING",
    END_ROUTE: "END_ROUTE"
}

export const DEFAULT_VALUE = {
    nodeId: null,
    routeCode: null,
    routeId: null,
    aisles: [],
    activeCart: null,
    ovPackages: null,
    pickItem: null,
    totalItems: null,
    stagingLocation: null,
    pickedItems: {},
    items: [],
    problemSolveItems: [],
    status: PICKING_STATUS.PRE_PICK
};

export const PICK_CONTEXT_ACTIONS = {
    SET_CONTEXT: "SET_CONTEXT",
    ADD_CART_TO_ROUTE: "ADD_CART_TO_ROUTE",
    MOVE_ITEM_TO_CART: "MOVE_ITEM_TO_CART",
    REPORT_ITEM: "REPORT_ITEM",
    STAGE_CART: "STAGE_CART",
    SET_STATUS: "SET_STATUS",
    RESET_CONTEXT: "RESET_CONTEXT"
}

export const PickingContextReducer = (state, action) => {
    switch (action.type) {
        case PICK_CONTEXT_ACTIONS.SET_CONTEXT:
            return {...state, ...action.value}
        case PICK_CONTEXT_ACTIONS.ADD_CART_TO_ROUTE:
            return {...state, activeCart: action.value}
        case PICK_CONTEXT_ACTIONS.MOVE_ITEM_TO_CART:
            return {...state, items: action.items, pickedItems: action.pickedItems}
        case PICK_CONTEXT_ACTIONS.REPORT_ITEM:
            return {...state, problemSolveItems: action.problemSolveItems, items: action.items};
        case PICK_CONTEXT_ACTIONS.STAGE_CART:
            return {...state, activeCart: null, status: PICKING_STATUS.END_ROUTE};
        case PICK_CONTEXT_ACTIONS.SET_STATUS:
            return {...state, status: action.value}
        case PICK_CONTEXT_ACTIONS.RESET_CONTEXT:
            return DEFAULT_VALUE;
        default:
            return state;
    }
}
