import React, {useContext, useEffect, useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import qrCodeImage from "../../../images/ic_qr_code.png";
import {AppNavigationContext} from "../../appNavigation/context/AppNavigationContext";
import {Logger, MeshEvents, SessionManager} from "@amzn/dolphin-web-framework";
import {updateAlert} from "../../../utils/utils";
import {uploadOpenBagAction, getSortZoneFromScannableId} from "./bagAction";
import {NOTIFICATION_STRING} from "../../../constants/constants";
import {ContainerPlanContext} from "../../containerPlan/context/ContainerPlanContext";

const OpenBagView = (props) => {

    const dolphinBus = new BroadcastChannel('DOLPHIN_BUS');
    const {stateContainerPlan} = useContext(ContainerPlanContext);
    const {navState: {helpOptions : {open: helpOpen}, onBackClicked}, navActions} = useContext(AppNavigationContext);
    const [view, setView] = useState(false);
    const [bagId, setBagId] = useState("");
    const [, setIsLoading] = useState(false);

    const onBagScan = (bagId) => {
        setView(true);
        setBagId(bagId);
    }

    const onLocationScan = (sortZoneScannableId) => {
        const sortZoneDestination = getSortZoneFromScannableId(stateContainerPlan.sortZoneMappings, sortZoneScannableId);
        if (!sortZoneDestination) {
            updateAlert(navActions, "error", <FormattedMessage id={NOTIFICATION_STRING.BAG_OPEN_SORT_ZONE_ERROR} />);
            Logger.log.info("Invalid destination scanned while opening bag: " + sortZoneScannableId);
        } else {
            setView(false);
            setIsLoading(true);
            updateAlert(navActions, "success", <FormattedMessage id={NOTIFICATION_STRING.BAG_OPEN_SUCCESS} values={{"bagId": bagId}} />);
            uploadOpenBagAction(bagId, sortZoneScannableId, stateContainerPlan.cycle, sortZoneDestination.label, sortZoneDestination.stationCode).then(() => {
                setIsLoading(false);
                setBagId("");
            });
        }
    }

    const onMessage = (event) => {
        try {
            const eventData = JSON.parse(event.data);
            Logger.log.info("EventData : " + JSON.stringify(eventData));
            if(eventData.eventName && eventData.eventName === MeshEvents.MESH_EVENTS.AVERY_SCAN
                && eventData.sessionId === SessionManager.getSessionId()) {
                if (view) {
                    onLocationScan(eventData.payload);
                } else {
                    onBagScan(eventData.payload);
                }
            }
        } catch (JSONParseException) {
            Logger.log.warn("Enable to parse event : " + event.data);
        }
    };

    const clear = () => {
        updateAlert(navActions);
        dolphinBus.removeEventListener('message', onMessage);
    }

    useEffect(() => {
        navActions.resetNavCol();
        navActions.closeOnBack();
        dolphinBus.addEventListener('message', onMessage);
        if(onBackClicked) {
            navActions.onBackDefault();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            clear();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scanEmptyBagView = () => {
        return (
            <Column height="100%" spacing="none" spacingInset="none">
                <Column height="100%" spacingInset="small">
                    <ScanWithCodeView
                        primaryTitle={{
                            title: "Scan empty bag",
                            size: "Large",
                            type: "Bold"
                        }}
                        image={qrCodeImage}
                        onSubmit={onBagScan}
                        focusLock={!helpOpen}
                    />
                </Column>
            </Column>
        );
    }

    const scanLocationView = () => {
        return (
            <Column spacingInset="medium" alignmentHorizontal="center">
                <ScanWithCodeView
                    primaryTitle={{
                        title: "Confirm bag is in right location",
                        size: "Large"
                    }}
                    secondaryTitle={{
                        title: "Scan location",
                        size: "Large",
                        type: "Bold"
                    }}
                    image={qrCodeImage}
                    onSubmit={onLocationScan}
                    focusLock={!helpOpen}
                />
            </Column>
        );
    }

    return (
        <div>
            {view ? scanLocationView() : scanEmptyBagView()}
        </div>
    );
}

export default injectIntl(OpenBagView);