import {DataHandler} from "@amzn/dolphin-web-framework";
import {saveInformationInDB} from "../../network/IndexedDbUtility";
import {
    OBJECT_STORE, OPERATION_STOW,
    TABLE
} from "../../../constants/constants";


const uploadStowAction = async (packageId, sortZoneScannableId, plannedSortZone, actualSortZone, cycle, exclusive, childStationCode) => {
    const today = new Date();
    const executionStationCode = DataHandler.getStationCode();
    const userId = DataHandler.getUserId();
    const userEmployeeId = DataHandler.getUserEmployeeId();
    const userLogin = DataHandler.getUserEmployeeLogin();
    const cycleInformation = (cycle[childStationCode] == null) ? "CYCLE_INFORMATION_NOT_FOUND" : cycle[childStationCode];
    const value = [today.getTime(), OPERATION_STOW, "SUCCESS", cycleInformation, executionStationCode, childStationCode,
        executionStationCode, userId, userEmployeeId, userLogin, packageId, sortZoneScannableId, actualSortZone, exclusive, plannedSortZone].join('~');

    const object = {
        "packageId": packageId,
        "value": value
    }

    saveInformationInDB(TABLE.STOW, OBJECT_STORE.STOW, OBJECT_STORE.STOW, object, OPERATION_STOW);
}

export {uploadStowAction};