import React, {useContext, useEffect} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {AppNavigationContext} from "../appNavigation/context/AppNavigationContext";
import {Routes} from "../../utils/Routes";
import icon_induct from "../../images/icon_induct.png";
import icon_stow from "../../images/icon_stow.png";
import icon_pick_stage from "../../images/icon_pick_stage.png"
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Button } from "@mui/material";
import {updateAlert} from "../../utils/utils";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import {NOTIFICATION_STRING} from "../../constants/constants";
import {load} from "./network/Loader";
import {ContainerPlanContext} from "../containerPlan/context/ContainerPlanContext";
import ImageListItem from '@mui/material/ImageListItem';
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";

const ScanPackageView = (props) => {

    const {stateContainerPlan, dispatchContainerPlan} = useContext(ContainerPlanContext);
    const {navActions} = useContext(AppNavigationContext);

    useEffect(() => {
        navActions.resetNavCol();
        navActions.closeOnBack();
        navActions.setupHelp(true, {list: []});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (stateContainerPlan.isInitialized === true) {
            updateAlert(navActions, "success",<FormattedMessage id={NOTIFICATION_STRING.ALERT_SUCCESS_CONTAINER_PLAN_DOWNLOADED_SUCCESSFULLY}/>);
        }
        else if (stateContainerPlan.isInitialized === false) {
            updateAlert(navActions, "error", <FormattedMessage id={NOTIFICATION_STRING.ALERT_ERROR_SOMETHING_WENT_WRONG}/>);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateContainerPlan.isInitialized]);

    useEffect(() => {
        if(null === stateContainerPlan.map) {
            load(dispatchContainerPlan);
        }
    }, [stateContainerPlan.map, dispatchContainerPlan])
    
    const inductOperation = () => {
        props.history.push(Routes.SCAN_PACKAGE);
        updateAlert(navActions);
    }

    const stowOperation = () => {
        props.history.push(Routes.STOW_OPTIONS);
        updateAlert(navActions);
    }

    const pickOperation = () => {
        props.history.push(Routes.PICK_OPERATION);
        updateAlert(navActions);
    }

    const theme = createTheme({
        palette: {
            neutral: {
              main: '#fff',
              contrastText: '#000',
            },
        },
        components: {
            MuiImageList: {
                styleOverrides: {
                    root: {
                        width: "auto",
                        height: "auto",
                    }
                }
            }
        },
    });

    const itemData = [
        {
          img: icon_induct,
          title: 'Induct',
          operation: inductOperation
        },
        {
            img: icon_stow,
            title: 'Stow',
            operation: stowOperation
        },
        {
            img: icon_pick_stage,
            title: 'Pick',
            operation: pickOperation
        }
    ];
    
    return (
        <ThemeProvider theme={theme}>
            {stateContainerPlan.isLoading ? <Loader/> : null}
            <Row>
            {itemData.map((item) => (
                <Button variant="contained" size="small" color="neutral" onClick={item.operation}>
                    <Column>
                        <Row>
                            <ImageListItem key={item.img} onClick={item.operation}>
                            <img
                                src={`${item.img}`}
                                alt={item.title}
                            />
                            </ImageListItem>
                        </Row>
                        <Row alignmentHorizontal="center">
                            {item.title}
                        </Row>
                    </Column>
                </Button>
            ))}
            </Row>
        </ThemeProvider>
    )
}
export default injectIntl(ScanPackageView);