import {Logger} from "@amzn/dolphin-web-framework";
import {OBJECT_STORE, TABLE} from "../../../constants/constants";

/**
 * Save object in indexedDB
 * @param tableName:{String} indexed db name
 * @param storeName:{String} indexed db store name
 * @param storeNameKey:{String} indexed db store key
 * @param data:{Object} object to be stored
 * @param operation:{String} name of the operation
 * @param override:{Boolean} override the existing object with the same key if exist
 */
export const saveInformationInDB = (tableName, storeName, storeNameKey, data, operation, override) => {
    saveToIndexedDb(tableName, storeName, storeNameKey, data, override)
        .then(() => {
            Logger.log.info(operation + JSON.stringify(data));
        })
        .catch(() => {
            Logger.log.error("Error " + operation + " " + JSON.stringify(data));
        });
}

const saveToIndexedDb = (tableName, objectStoreName, key, object, override) => {
    return new Promise(function(resolve, reject) {
        const request = indexedDB.open(tableName, 1);

        request.onerror = function(event) {
            Logger.log.error(`Some error occurred in indexedDB ${tableName}:` + request.error);
            reject();
        };

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            if(key) {
                db.createObjectStore(objectStoreName, { keyPath: key });
            } else {
                db.createObjectStore(objectStoreName, { keyPath: "id", autoIncrement: true });
            }
            resolve();
        };

        request.onsuccess = function(event) {
            try {
                const db = event.target.result;
                const objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);
                if(override) {
                    objectStore.put(object);
                } else {
                    objectStore.add(object);
                }
                resolve();
            } catch (err) {
                Logger.log.error("Failure adding object to indexedDB: " + tableName + JSON.stringify(err));
                reject();
            }
        };
    })
}

/**
 * Save/update the PickContext in indexed db
 * @param context:{Object} PickContext to be saved/updated
 */
export const savePickContextInIndexedDB = (context) => {
    context[OBJECT_STORE.PICK_CONTEXT] = OBJECT_STORE.PICK_CONTEXT;
    saveInformationInDB(TABLE.PICK_CONTEXT, OBJECT_STORE.PICK_CONTEXT, OBJECT_STORE.PICK_CONTEXT, context, "SAVE_PICK_CONTEXT", true);
}

/**
 * Retrieves the object from indexed db by Id
 * @param tableName:{String} indexed db name
 * @param objectStoreName:{String} indexed db object store name
 * @param keyPath:{String} indexed db object store key path
 * @param objectId:{String} key of the object to be retrieved
 * @returns {Promise<Object>}
 */
export const getObjectByIdFromIndexedDB = function (tableName, objectStoreName, keyPath, objectId) {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(tableName, 1);

        request.onerror = function () {
            Logger.log.error(`Some error occurred in indexedDB ${tableName}:` + request.error);
            reject();
        }

        request.onupgradeneeded = function (event) {
            const db = event.target.result;
            db.createObjectStore(objectStoreName, {keyPath: keyPath});
            resolve();
        }

        request.onsuccess = function (event) {
            try{
                const db = event.target.result;
                const store = db.transaction(objectStoreName, "readonly").objectStore(objectStoreName);
                const query = store.get(objectId);
                query.onsuccess = function (event) {
                    resolve(query.result);
                }
                query.onerror = function (event) {
                    Logger.log.error(`Error while getting record with id: ${objectId} from ${tableName}`);
                    reject();
                }
            } catch (err) {
                Logger.log.error(`Error fetching data from object store: ${objectStoreName}`);
                reject();
            }
        }
    });
}

/**
 * Retrieves the objects/records from indexed db
 * @param table:{String} indexed db name
 * @param objectStoreKey:{String} indexed db object store name
 * @returns {Promise<List<Object>>}
 */
export const getRecordsFromIndexedDB = (table, objectStoreKey) => {
    return new Promise(function(resolve, reject){
        const request = indexedDB.open(table, 1);

        request.onerror = function(event) {
            Logger.log.error("Error occurred in indexedDB: " + table);
            reject();
        };

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            db.createObjectStore(objectStoreKey, { keyPath: objectStoreKey });
            resolve();
        };

        request.onsuccess = function(event) {
            try {
                let content = [];
                const db = event.target.result;
                const objectStore = db.transaction(objectStoreKey, "readwrite").objectStore(objectStoreKey);
                objectStore.openCursor().onsuccess = function(event) {
                    let cursor = event.target.result;
                    if (cursor) {
                        content.push(cursor.value.value);
                        cursor.continue();
                    }
                    else {
                        resolve(content)
                    }
                };
            } catch (err) {
                Logger.log.error(`Error occurred while fetching data from indexedDB: ${table}:${objectStoreKey}`, err);
                reject();
            }
        };
    });
}

/**
 * Delete the indexed db by name
 * @param tableName:{String} indexed db name
 */
export const clearDbByName = (tableName) => {
    indexedDB.deleteDatabase(tableName);
}
