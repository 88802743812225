import {DataHandler} from "@amzn/dolphin-web-framework";
import {saveInformationInDB} from "../../network/IndexedDbUtility";
import {
    OBJECT_STORE, OPERATION_BAG_CLOSE,
    OPERATION_BAG_OPEN,
    TABLE
} from "../../../constants/constants";

const uploadOpenBagAction = async (bagId, sortZoneScannableId, cycle, sortZone, childStationCode) => {
    const today = new Date();
    const executionStationCode = DataHandler.getStationCode();
    const userId = DataHandler.getUserId();
    const userEmployeeId = DataHandler.getUserEmployeeId();
    const userLogin = DataHandler.getUserEmployeeLogin();
    const cycleInformation = (cycle[childStationCode] == null) ? "CYCLE_INFORMATION_NOT_FOUND" : cycle[childStationCode];
    const value = [today.getTime(), OPERATION_BAG_OPEN, "SUCCESS", cycleInformation, executionStationCode, childStationCode,
        executionStationCode, userId, userEmployeeId, userLogin, bagId, sortZoneScannableId, sortZone].join('~');

    const object = {
        "bagId": bagId,
        "value": value
    }

    saveInformationInDB(TABLE.OPEN_BAG, OBJECT_STORE.OPEN_BAG, OBJECT_STORE.OPEN_BAG, object, OPERATION_BAG_OPEN);
}

const uploadCloseBagAction = async (bagId, sortZoneScannableId, cycle, sortZone, childStationCode) => {
    const today = new Date();
    const executionStationCode = DataHandler.getStationCode();
    const userId = DataHandler.getUserId();
    const userEmployeeId = DataHandler.getUserEmployeeId();
    const userLogin = DataHandler.getUserEmployeeLogin();
    const cycleInformation = (cycle[childStationCode] == null) ? "CYCLE_INFORMATION_NOT_FOUND" : cycle[childStationCode];
    const value = [today.getTime(), OPERATION_BAG_CLOSE, "SUCCESS", cycleInformation, executionStationCode, childStationCode,
        executionStationCode, userId, userEmployeeId, userLogin, bagId, sortZoneScannableId, sortZone].join('~');

    const object = {
        "bagId": bagId,
        "value": value
    }

    saveInformationInDB(TABLE.CLOSE_BAG, OBJECT_STORE.CLOSE_BAG, OBJECT_STORE.CLOSE_BAG, object, OPERATION_BAG_CLOSE);
}

const getSortZoneFromScannableId = (sortZoneMappings, sortZoneScannableId) => {
    for (let sortZone in sortZoneMappings) {
        for (let i = 0; i < sortZoneMappings[sortZone].length; i++) {
            let destination = sortZoneMappings[sortZone][i];
            if (destination.scannableId === sortZoneScannableId) {
                return destination;
            }
        }
    }

    return null;
};

export {uploadOpenBagAction, uploadCloseBagAction, getSortZoneFromScannableId};