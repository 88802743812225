import {SoundType} from "../sound/SoundManager";
import {Logger} from "@amzn/dolphin-web-framework";
const VIBRATION_DURATION_IN_MS = 200;

export const updateAlert = (navActions, type, message) => {

    // Clear Alert if no type is specified
    if (typeof type === "undefined") navActions.clearAlert();
    else if (type === "") navActions.clearAlert();
    else {
        playSoundByAlertType(type);
        // Show Alert
        navActions.showAlert(type, message, () => {
                navActions.clearAlert();
                navActions.resetNavCol();
        });
    }
    // Set NavBar color
    if (type === "error") navActions.errorNavCol();
    else navActions.resetNavCol();
}

function playSoundByAlertType(alertType) {
    switch(alertType) {
        case 'success':
            SoundType.BEEP.load();
            SoundType.BEEP.play().catch(error => {
                Logger.log.warn("Unable to play sound : " + error);
            });
            window.navigator.vibrate(VIBRATION_DURATION_IN_MS);
            break;
        default:
            SoundType.BEEP_ERROR.load();
            SoundType.BEEP_ERROR.play().catch(error => {
                Logger.log.warn("Unable to play sound : " + error);
            });
            window.navigator.vibrate(VIBRATION_DURATION_IN_MS);
    }
}