import React, {useContext, useEffect, useState} from "react";
import Column from "@amzn/meridian/column";
import {Loader, ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import {MeshEvents, SessionManager} from "@amzn/dolphin-web-framework";
import {FormattedMessage, injectIntl} from "react-intl";
import packageScanImage from "../../images/ic_package_scan.png";
import {ContainerPlanContext} from "../containerPlan/context/ContainerPlanContext";
import {AppNavigationContext} from "../appNavigation/context/AppNavigationContext";
import {updateAlert} from "../../utils/utils";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {getSALOutput, upload} from "./action/scanPackageAction";
import {Logger} from "@amzn/dolphin-web-framework";
import {NOTIFICATION_STRING} from "../../constants/constants";


const ScanPackageView = (props) => {

    const dolphinBus = new BroadcastChannel('DOLPHIN_BUS');
    const {stateContainerPlan} = useContext(ContainerPlanContext);
    const {navState: {helpOptions : {open: helpOpen}, onBackClicked}, navActions} = useContext(AppNavigationContext);
    const [isLoading, setIsLoading] = useState(false);

    const onMessage = (event) => {
        try {
            const eventData = JSON.parse(event.data);
            Logger.log.info("EventData : " + JSON.stringify(eventData));
            if(eventData.eventName && eventData.eventName === MeshEvents.MESH_EVENTS.AVERY_SCAN
                && eventData.sessionId === SessionManager.getSessionId()) {
                averyScanValue(eventData.payload);
            }
        } catch (JSONParseException) {
            Logger.log.warn("Enable to parse event : " + event.data);
        }
    };

    const clear = () => {
        updateAlert(navActions);
        dolphinBus.removeEventListener('message', onMessage);
        Logger.log.info("ScanPackageView Unmount");
    }

    const onPackageScan = (packageId) => {
        let stationCode;
        let parentStationCode;
        let sortZone;

        Logger.log.info("In Scan Package Action " + packageId + " - " + JSON.stringify(Object.keys(stateContainerPlan.map).slice(0, 10)));
        if(stateContainerPlan.map) {
            Logger.log.info("REACHED INSIDE Map existence check");
            if(stateContainerPlan.map[packageId]) {
                Logger.log.info("REACHED INSIDE package existence check");
                stationCode = stateContainerPlan.map[packageId].stationCode;
                parentStationCode = stateContainerPlan.map[packageId].parentStationCode;
                sortZone = stateContainerPlan.map[packageId].sortZone;
            }
            Logger.log.info("Printing all information " + stationCode + "-" + parentStationCode + "-" + sortZone);
            if(sortZone === undefined) {
                updateAlert(navActions, "error", <FormattedMessage id={NOTIFICATION_STRING.ALERT_ERROR_SORTZONE_NOT_FOUND} values={{"package": packageId}} />);
                setIsLoading(true);
                upload(packageId, "FAILURE", "NOT_FOUND", stateContainerPlan.cycle[stationCode], stationCode, parentStationCode).then(() => {
                    NativeMeshInteractor.printAveryLabel(getSALOutput('C-Plan', packageId), "InductSAL");
                    setIsLoading(false);
                });
            } else {
                updateAlert(navActions, "success", <FormattedMessage id={NOTIFICATION_STRING.ALERT_SUCCESS_SORTZONE_FOUND} values={{"package": packageId, "sortZone": sortZone}} />);
                setIsLoading(true);
                upload(packageId, "SUCCESS", sortZone, stateContainerPlan.cycle[stationCode], stationCode, parentStationCode).then(() => {
                    NativeMeshInteractor.printAveryLabel(getSALOutput(sortZone, packageId), "InductSAL");
                    setIsLoading(false);
                });
            }
        }
    }

    const averyScanValue = (scanValue) => {
        if (helpOpen) window.history.back();
        if(scanValue) {
            onPackageScan(scanValue);
        }
    }

    useEffect(() => {
        navActions.resetNavCol();
        navActions.closeOnBack();
        navActions.setupHelp(true, {list: []});
        dolphinBus.addEventListener('message', onMessage);
        if(onBackClicked) {
            navActions.onBackDefault();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        Logger.log.info("ScanPackageView Mount");
        return () => {
            clear();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Column height="100%" spacing="none" spacingInset="none">
            <Column height="100%" spacingInset="small">
                {isLoading ? <Loader/> : null}
                <ScanWithCodeView
                    primaryTitle={{
                        title: "Scan package",
                        size: "Large",
                        type: "Bold"
                    }}
                    image={packageScanImage}
                    onSubmit={onPackageScan}
                    focusLock={!helpOpen}
                />
            </Column>
        </Column>
    )
}

export default injectIntl(ScanPackageView);
