import {Logger} from "@amzn/dolphin-web-framework";
import {getS3Client} from "../../utils/S3Client";
import {getRecordsFromIndexedDB} from "../pickStage/utils/pickDbUtils";

export const uploadToS3 = (bucketName, key, content) => {
    const s3 = getS3Client();
    return new Promise((resolve, reject) => {
        s3.putObject({
            Bucket: bucketName,
            Key: key,
            Body: content
        }, (err, data) => {
            if (err) {
                Logger.log.error("Error while uploading file to S3 bucket :", err, data);
                reject(err);
            } else {
                Logger.log.info("File uploaded :", data);
                resolve(data);
            }
        });
    });
}

export const syncTablesToS3 = (table, objectStoreKey, bucketName, fileName) => {
    return new Promise(function(resolve, reject){
        getRecordsFromIndexedDB(table, objectStoreKey).then(records => {
            const fileContent = records.join('\n');
            uploadToS3(bucketName, fileName, fileContent)
                .then(() => {
                    Logger.log.info("Successfully uploaded to S3");
                    resolve();
                })
                .catch((err) => {
                    Logger.log.info('Error uploading object' + err);
                    reject();
                });
        }).catch(() => {
            Logger.log.error(`Error while synchronizing ${table} to S3`);
            reject();
        });
    });
};
