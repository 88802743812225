import React, {createContext, useReducer} from "react";
import { ContainerPlanReducer } from "../reducer/ContainerPlanReducer";

export const ContainerPlanContext = createContext();

const ContainerPlanContextProvider = (props) => {
    const initState = {
        isInitialized: null,
        isLoading: false,
        map: null,
        cycle: null,
        sortZoneMappings: null
    };
    const [stateContainerPlan, dispatchContainerPlan] = useReducer(ContainerPlanReducer, initState);

    return (
        <ContainerPlanContext.Provider value={{stateContainerPlan, dispatchContainerPlan}}>
            {props.children}
        </ContainerPlanContext.Provider>
    )
}

export default ContainerPlanContextProvider;
