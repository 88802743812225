import React, {useContext, useEffect, useState} from "react";
import {PickingContext} from "./context/pickingContext";
import {injectIntl} from "react-intl";
import ScanBagView from "./scanBagView";
import ScanCartView from "./scanCartView";
import AddCartView from "./addCartView";
import {PICKING_STATUS} from "./reducer/PickingContextReducer";

const Picking = (props) => {
    const {pickingState, pickingActions} = useContext(PickingContext);
    const [scanCart, setScanCart] = useState(false);


    useEffect(() => {
        if(pickingState.items.length === 0) {
            pickingActions.setStatus(PICKING_STATUS.STAGING);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickingState.items]);

    return (
        !pickingState.activeCart ? <AddCartView /> :
            (scanCart ? <ScanCartView setScanCart={setScanCart} /> : <ScanBagView setScanCart={setScanCart} />)
    );


}

export default injectIntl(Picking);
