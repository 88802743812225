import {ApplicationActions} from "../action/ApplicationActions";

export const ContainerPlanReducer = (state, action) => {
    switch (action.type) {
        case ApplicationActions.UPDATE_MAP:
            return {...state, map: action.data.map, cycle: action.data.cycle, sortZoneMappings: action.data.sortZoneMappings, isInitialized: true, isLoading: false};
        case ApplicationActions.ERROR_MAP:
            return {...state, isInitialized: false, isLoading: false};
        case ApplicationActions.LOADING_MAP:
            return {...state, isLoading: true};
        default:
            return state;
    }
}
