export const THRESHOLD = 20;
export const SYNC_TIME = 60*1000;
export const DELIMITER = "/";
export const DATE_OFFSET = 24*60*60*1000;
export const S3_PREFIX = "s3://";
export const XPT = "XPT";
export const OPERATION_INDUCT = "INDUCT";
export const OPERATION_BAG_OPEN = "BAG_OPEN";
export const OPERATION_BAG_CLOSE = "BAG_CLOSE";
export const OPERATION_STOW = "STOW"
export const BUCKET_BETA = "induct-scans-beta";
export const BUCKET_GAMMA_NA = "induct-scans-gamma-na";
export const BUCKET_GAMMA_EU = "induct-scans-gamma-eu";
export const BUCKET_GAMMA_FE = "induct-scans-gamma-fe";
export const BUCKET_PROD_NA = "induct-scans-prod-na";
export const BUCKET_PROD_EU = "induct-scans-prod-eu";
export const BUCKET_PROD_FE = "induct-scans-prod-fe";
export const BUCKET_BETA_CP = "container-plan-beta";
export const BUCKET_GAMMA_NA_CP = "container-plan-gamma-na";
export const BUCKET_GAMMA_EU_CP = "container-plan-gamma-eu";
export const BUCKET_GAMMA_FE_CP = "container-plan-gamma-fe";
export const BUCKET_PROD_NA_CP = "container-plan-prod-na";
export const BUCKET_PROD_EU_CP = "container-plan-prod-eu";
export const BUCKET_PROD_FE_CP = "container-plan-prod-fe";
export const BUCKET_BETA_OPEN_BAG = "open-bag-beta";
export const BUCKET_GAMMA_NA_OPEN_BAG = "open-bag-gamma-na";
export const BUCKET_GAMMA_EU_OPEN_BAG = "open-bag-gamma-eu";
export const BUCKET_GAMMA_FE_OPEN_BAG = "open-bag-gamma-fe";
export const BUCKET_PROD_NA_OPEN_BAG = "open-bag-prod-na";
export const BUCKET_PROD_EU_OPEN_BAG = "open-bag-prod-eu";
export const BUCKET_PROD_FE_OPEN_BAG = "open-bag-prod-fe";
export const BUCKET_BETA_CLOSE_BAG = "close-bag-beta";
export const BUCKET_GAMMA_NA_CLOSE_BAG = "close-bag-gamma-na";
export const BUCKET_GAMMA_EU_CLOSE_BAG = "close-bag-gamma-eu";
export const BUCKET_GAMMA_FE_CLOSE_BAG = "close-bag-gamma-fe";
export const BUCKET_PROD_NA_CLOSE_BAG = "close-bag-prod-na";
export const BUCKET_PROD_EU_CLOSE_BAG = "close-bag-prod-eu";
export const BUCKET_PROD_FE_CLOSE_BAG = "close-bag-prod-fe";
export const BUCKET_BETA_STOW = "stow-beta";
export const BUCKET_GAMMA_NA_STOW = "stow-gamma-na";
export const BUCKET_GAMMA_EU_STOW = "stow-gamma-eu";
export const BUCKET_GAMMA_FE_STOW = "stow-gamma-fe";
export const BUCKET_PROD_NA_STOW = "stow-prod-na";
export const BUCKET_PROD_EU_STOW = "stow-prod-eu";
export const BUCKET_PROD_FE_STOW = "stow-prod-fe";

export const BUCKET_BETA_ROUTE_ASSIGNMENT = "route-assignment-beta";
export const BUCKET_GAMMA_NA_ROUTE_ASSIGNMENT = "route-assignments-gamma-na";
export const BUCKET_GAMMA_EU_ROUTE_ASSIGNMENT = "route-assignments-gamma-eu";
export const BUCKET_GAMMA_FE_ROUTE_ASSIGNMENT = "route-assignments-gamma-fe";

export const BUCKET_PROD_NA_ROUTE_ASSIGNMENT = "route-assignments-prod-na";
export const BUCKET_PROD_EU_ROUTE_ASSIGNMENT = "route-assignments-prod-eu";
export const BUCKET_PROD_FE_ROUTE_ASSIGNMENT = "route-assignments-prod-fe";

export const BUCKET_BETA_ROUTE_INFO = "route-info-beta";
export const BUCKET_GAMMA_NA_ROUTE_INFO = "route-info-gamma-na";
export const BUCKET_GAMMA_EU_ROUTE_INFO = "route-info-gamma-eu";
export const BUCKET_GAMMA_FE_ROUTE_INFO = "route-info-gamma-fe";

export const BUCKET_PROD_NA_ROUTE_INFO = "route-info-prod-na";
export const BUCKET_PROD_EU_ROUTE_INFO = "route-info-prod-eu";
export const BUCKET_PROD_FE_ROUTE_INFO = "route-info-prod-fe";

export const BUCKET_BETA_ROUTE_PICK_COMPLETE = "route-pick-complete-beta";
export const BUCKET_GAMMA_NA_ROUTE_PICK_COMPLETE = "route-pick-complete-gamma-na";
export const BUCKET_GAMMA_EU_ROUTE_PICK_COMPLETE = "route-pick-complete-gamma-eu";
export const BUCKET_GAMMA_FE_ROUTE_PICK_COMPLETE = "route-pick-complete-gamma-fe";

export const BUCKET_PROD_NA_ROUTE_PICK_COMPLETE = "route-pick-complete-prod-na";
export const BUCKET_PROD_EU_ROUTE_PICK_COMPLETE = "route-pick-complete-prod-eu";
export const BUCKET_PROD_FE_ROUTE_PICK_COMPLETE = "route-pick-complete-prod-fe";

export const APP_NAVIGATION_STRINGS = {
    dolphin_fallback: "dolphin_fallback",
    scan_container_plan: "scan_container_plan",
    help_center: "help_center",
    scan_package: "scan_package",
    scan_empty_bag: "scan_empty_bag",
    scan_location: "scan_location"
}

export const NOTIFICATION_STRING = {
    ALERT_ERROR_SOMETHING_WENT_WRONG: "alert.error.something_went_wrong",
    ALERT_SUCCESS_CONTAINER_PLAN_DOWNLOADED_SUCCESSFULLY: "alert.success.container_plan_downloaded_successfully",
    ALERT_ERROR_SORTZONE_NOT_FOUND: "alert.error.sortzone_not_found",
    ALERT_SUCCESS_SORTZONE_FOUND: "alert.success.sortzone_found",
    ALERT_WARNING_DATA_SYNCING: "alert.warning.data_syncing",
    BAG_OPEN_SUCCESS: "bag.open.success",
    BAG_OPEN_SORT_ZONE_ERROR: "bag.open.sort_zone_error",
    BAG_CLOSE_SUCCESS: "bag.close.success",
    BAG_CLOSE_ERROR: "bag.close.sort_zone_error",
    STOW_PACKAGE_SUCCESS: "stow.package.success",
    STOW_PACKAGE_ERROR: "stow.package.error",
    PICK_CART_OPEN: "pick.cart.open",
    PICK_INCORRECT_ITEM: "pick.incorrect.item",
    PICK_INVALID_CART: "pick.invalid.cart",
    INCORRECT_STAGING_LOCATION: "incorrect.staging.location",
    ITEM_PICKED: "item.picked",
    ALREADY_ASSIGNED_ROUTE: "already.assigned.route",
    CART_STAGED: "cart.staged",
    PICKING_COMPLETED: "picking.completed",
    PICK_ERROR: "pick.error",
    STAGING_AREA_NOT_VALID: "staging.area.not.valid",
    STAGING_EMPTY_CART_ERROR: "staging.empty.cart.error",
    REPORT_ITEM: "report.item",
    CART_STAGED_TO_LOCATION: "cart.staged.to.location",
    UNEXPECTED_ERROR: "unexpected.error"
}

export const SUFFIX = {
    CONTAINER_PLAN: "_container_plan.json",
    METADATA: "_metadata.json",
    SORT_ZONE_MAPPING: "_sort_zone_destinations_mapping.json"
}

export const TABLE = {
    CONTAINER_PLAN: "ContainerPlan",
    INDUCT: "Induct",
    METADATA: "Metadata",
    OPEN_BAG: "OpenBag",
    CLOSE_BAG: "CloseBag",
    STOW: "Stow",
    SORT_ZONE_MAPPING: "SortZoneMapping",
    STATION_CODES: "StationCodes",
    PICK_ACTION: "PickAction",
    PICK_CONTEXT: "PickContext"
}

export const OBJECT_STORE = {
    CONTAINER_PLAN: "stationCode",
    INDUCT: "packageId",
    METADATA: "stationCode",
    OPEN_BAG: "bagId",
    CLOSE_BAG: "bagId",
    STOW: "packageId",
    SORT_ZONE_MAPPING: "stationCode",
    PICK_ACTION: "PickAction",
    PICK_CONTEXT: "PickContext"
}

export const PICK_OPERATION = {
    ASSIGN_ROUTE: "AssignRoute",
    ADD_CART_TO_ROUTE: "AddCartToRoute",
    MOVE_ITEM_TO_CART: "MoveItemToCart",
    REPORT_ITEM: "ReportItem",
    STAGE_CART: "StageCart",
    END_ROUTE: "EndRoute",
}

export const STRING_CONSTANT = {
    PICK_REMAINING_PACKAGE: "Pick remaining packages",
    PICK_COMPLETED: "Picking completed",
    SCAN_NEW_CART: "Scan a new cart and continue with picking",
    PICK_NEW_ROUTE: "Pick new route",
    CONTINUE: "Continue",
    END_PICKING: "End picking",
    SCAN_STAGING_LOCATION: "Scan the staging location ",
    SCAN_AVAILABLE_STAGING_LOCATION: "Please scan any available staging location",
    STAGE_CART: "Stage the cart",
    ATTACH_CART_FOR_PICKING: "Attach cart for picking",
    SCAN_CART: "Scan new cart",
    UNEXPECTED_ERROR: "Unexpected error occurred"
}
