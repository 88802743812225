import {AppNavigationActions} from "../actions/AppNavigationActions";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";

export const AppNavigationReducer = (state, action) => {

    switch (action.type) {
        case AppNavigationActions.SETUP_HELP:
            return {...state, helpEnabled: action.value.enabled, helpOptions: {...state.helpOptions, ...action.value.options}};

        case AppNavigationActions.OPEN_HELP:
            return {...state, helpOptions: {...state.helpOptions, open: true}};

        case AppNavigationActions.CLOSE_HELP:
            return {...state, helpOptions: {...state.helpOptions, open: false}};

        case AppNavigationActions.ERROR_COL:
            return {...state, navType: 'error'};

        case AppNavigationActions.RESET_COL:
            return {...state, navType: 'default'};

        case AppNavigationActions.CLOSE_ON_BACK:
            return {...state, onBackClicked: NativeMeshInteractor.exitModule};

        case AppNavigationActions.CALLBACK_ON_BACK:
            return {...state, onBackClicked: action.callback};

        case AppNavigationActions.SET_LOADER:
            return {...state, loading: action.state};

        case AppNavigationActions.SHOW_ALERT:
            return {...state, alert: {show: true, type: action.alertType, message: action.message, onClose: action.onClose}};

        case AppNavigationActions.CLEAR_ALERT:
            return {...state, alert: {show: false, type: "", message: "", onClose: null}};

        default:
            return state;
    }
}
