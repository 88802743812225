import React, {useContext, useEffect, useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import Box from "@amzn/meridian/box";
import {HeaderView, ScanWithCodeView, SnapshotView} from "@amzn/dolphin-uiweb-framework";
import qrCodeImage from "../../../images/ic_qr_code.png";
import {AppNavigationContext} from "../../appNavigation/context/AppNavigationContext";
import {Logger, MeshEvents, SessionManager} from "@amzn/dolphin-web-framework";
import {updateAlert} from "../../../utils/utils";
import {getSortZoneFromScannableId, uploadCloseBagAction} from "./bagAction";
import {NOTIFICATION_STRING} from "../../../constants/constants";
import {ContainerPlanContext} from "../../containerPlan/context/ContainerPlanContext";

const CloseBagView = (props) => {

    const dolphinBus = new BroadcastChannel('DOLPHIN_BUS');
    const {stateContainerPlan} = useContext(ContainerPlanContext);
    const {navState: {helpOptions : {open: helpOpen}, onBackClicked}, navActions} = useContext(AppNavigationContext);
    const [view, setView] = useState(false);
    const [bagId, setBagId] = useState("");
    const [, setIsLoading] = useState(false);

    const onBagScan = (bagId) => {
        setView(true);
        setBagId(bagId);
    }

    const onLocationScan = (overflowAreaScannableId) => {
        const sortZoneDestination = getSortZoneFromScannableId(stateContainerPlan.sortZoneMappings, overflowAreaScannableId);
        if (!sortZoneDestination) {
            updateAlert(navActions, "error", <FormattedMessage id={NOTIFICATION_STRING.BAG_CLOSE_ERROR} />);
            Logger.log.info("Invalid destination scanned while closing bag: " + overflowAreaScannableId);
        } else {
            setView(false);
            setIsLoading(true);
            updateAlert(navActions, "success", <FormattedMessage id={NOTIFICATION_STRING.BAG_CLOSE_SUCCESS} values={{"bagId": bagId}} />);
            uploadCloseBagAction(bagId, overflowAreaScannableId, stateContainerPlan.cycle, sortZoneDestination.stationCode, sortZoneDestination.label).then(() => {
                setIsLoading(false);
                setBagId("");
            });
        }

    }

    const onMessage = (event) => {
        try {
            const eventData = JSON.parse(event.data);
            Logger.log.info("EventData : " + JSON.stringify(eventData));
            if(eventData.eventName && eventData.eventName === MeshEvents.MESH_EVENTS.AVERY_SCAN
                && eventData.sessionId === SessionManager.getSessionId()) {
                if (view) {
                    onLocationScan(eventData.payload);
                } else {
                    onBagScan(eventData.payload);
                }
            }
        } catch (JSONParseException) {
            Logger.log.warn("Enable to parse event : " + event.data);
        }
    };

    const clear = () => {
        updateAlert(navActions);
        dolphinBus.removeEventListener('message', onMessage);
    }

    useEffect(() => {
        navActions.resetNavCol();
        navActions.closeOnBack();
        dolphinBus.addEventListener('message', onMessage);
        if(onBackClicked) {
            navActions.onBackDefault();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            clear();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scanBagView = () => {
        return (
            <Column height="100%" spacing="none" spacingInset="none">
                <Column height="100%" spacingInset="small">
                    <ScanWithCodeView
                        primaryTitle={{
                            title: "Close the bag to be moved",
                            size: "Medium"
                        }}
                        secondaryTitle={{
                            title: "Scan bag",
                            size: "Large",
                            type: "Bold"
                        }}
                        image={qrCodeImage}
                        onSubmit={onBagScan}
                        focusLock={!helpOpen}
                    />
                </Column>
            </Column>
        );
    }

    const HeadingView = () => {

        const getTitles = () => {
            return {
                primaryTitle: getNormalTitle("Move bag to the overflow area"),
                secondaryTitle: getBoldTitle("Scan location")
            };
        }

        const getBoldTitle = (title) => {
            return {
                title: title,
                type: "Bold",
                size: "Large"
            }
        }

        const getNormalTitle = (title) => {
            return {
                title: title
            }
        }

        return <HeaderView {...getTitles()}/>
    }

    const scanLocationView = () => {
        return (
            <Column spacingInset="small">
                <Box type="outline" spacingInset="small">
                    <Box spacingInset="small">
                        <HeadingView></HeadingView>
                        <SnapshotView title={bagId} headerFontSize="Medium"
                                      description="Bag Id"/>
                    </Box>
                </Box>
                <ScanWithCodeView onSubmit={onLocationScan} focusLock={true}/>
            </Column>
        );
    };

    return (
        <div>
            {view ? scanLocationView() : scanBagView()}
        </div>
    );
}

export default injectIntl(CloseBagView);