/**
 * Routes for all Induct screens
 */
export const Routes = {
    // Scan container plan
    HOME: "/",
    SCAN_PACKAGE: "/scanPackage",
    INDEXED_DB: "/indexedDB",
    STOW_READINESS: "/stowReadiness",
    STOW_OPTIONS: "/stowOptions",
    CLOSE_BAG: "/closeBag",
    STOW_PACKAGE: "/stowPackage",
    PICK_OPERATION: "/pickOperation",
};
