import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import {DetailsCardView, HeaderView} from "@amzn/dolphin-uiweb-framework";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {PickingContext} from "./context/pickingContext";

const RouteDetailsView = (props) => {

    const {pickingState} = useContext(PickingContext);

    const getDetails = () => {
        const details = [
            {title: pickingState.routeId, description: "Assigned route id"},
            {title: pickingState.routeCode, description: "Assigned route code"},
            {title: pickingState.totalItems, description: "Total items"},
            {title: pickingState.ovPackages, description: "OV packages"},
        ]
        if(pickingState.stagingLocation) {
            details.push({title: pickingState.stagingLocation, description: "Staging location"});
        }
        const aisles = pickingState.aisles.filter(aisle => aisle.length).join(', ');
        if(aisles) {
            details.push({title: aisles, description: "Aisles"});
        }
        return details
    }

    return (
        <Column spacing={"none"}>
            <Column spacingInset="medium" heights="fill">
                <HeaderView primaryTitle={{title: "Route Details", type: "Bold", size: "Large"}}/>
                <DetailsCardView snapshots={getDetails()}/>
            </Column>
            <Column className={"footer"}>
                <Row spacingInset={"medium"} widths="fill" spacing={"none"}>
                    <Button onClick={props.onContinueClicked} size="large" type="primary">
                        Continue
                    </Button>
                </Row>
            </Column>
        </Column>
    );
};

export default injectIntl(RouteDetailsView);
