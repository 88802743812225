import React from "react";
import MeridianAlert from "@amzn/meridian/alert";
import Column from "@amzn/meridian/column";

export default function Alert(props) {
    return (
        <Column spacingInset="none small">
            <MeridianAlert type={props.type} size="medium" onClose={props.onClose}>{props.message}</MeridianAlert>
        </Column>
    );
}