import React, {useContext} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import qrCodeImage from "../../images/ic_qr_code.png";
import {NOTIFICATION_STRING, PICK_OPERATION, STRING_CONSTANT} from "../../constants/constants";
import {updateAlert} from "../../utils/utils";
import {PickingContext} from "./context/pickingContext";
import {AppNavigationContext} from "../appNavigation/context/AppNavigationContext";
import {saveAction} from "./actions/pickAndStageActions";

const AddCartView = (props) => {
    const {navActions} = useContext(AppNavigationContext);
    const {pickingState, pickingActions} = useContext(PickingContext);

    const attachCartToRoute = (cart) => {
        saveAction(PICK_OPERATION.ADD_CART_TO_ROUTE,[pickingState.routeCode, pickingState.routeId, cart]);
        pickingActions.attachCart(cart);
        updateAlert(navActions, "success", <FormattedMessage id={NOTIFICATION_STRING.PICK_CART_OPEN} values={{"cart": cart}}/>);
    }

    return (
        <Column>
            <Column spacingInset={"medium"} height="100%" heights="fill">
                <ScanWithCodeView
                    primaryTitle={{
                        title: STRING_CONSTANT.ATTACH_CART_FOR_PICKING
                    }}
                    secondaryTitle={{
                        title: STRING_CONSTANT.SCAN_CART,
                        size: "Large",
                        type: "Bold"
                    }}
                    image={qrCodeImage} onSubmit={attachCartToRoute} focusLock={true}/>
            </Column>
        </Column>
    );
}

export default injectIntl(AddCartView);
