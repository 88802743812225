import React, {useContext, useEffect, useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import {HeaderView, ScanWithCodeView, SnapshotView} from "@amzn/dolphin-uiweb-framework";
import qrCodeImage from "../../../images/ic_qr_code.png";
import {AppNavigationContext} from "../../appNavigation/context/AppNavigationContext";
import {Logger, MeshEvents, SessionManager} from "@amzn/dolphin-web-framework";
import {updateAlert} from "../../../utils/utils";
import {uploadStowAction} from './stowPackageAction';
import {NOTIFICATION_STRING} from "../../../constants/constants";
import {ContainerPlanContext} from "../../containerPlan/context/ContainerPlanContext";
import Box from "@amzn/meridian/box";

const StowPackageView = (props) => {

    const dolphinBus = new BroadcastChannel('DOLPHIN_BUS');
    const {stateContainerPlan} = useContext(ContainerPlanContext);
    const {navState: {helpOptions : {open: helpOpen}, onBackClicked}, navActions} = useContext(AppNavigationContext);
    const [view, setView] = useState(false);
    const [packageId, setPackageId] = useState("");
    const [sortZone, setSortZone] = useState("");
    const [aisle, setAisle] = useState("");
    const [shelf, setShelf] = useState("");
    const [, setIsLoading] = useState(false);

    const onPackageScan = (packageId) => {
        if (stateContainerPlan.map && stateContainerPlan.map[packageId]) {
            setView(true);
            setPackageId(packageId);
            setSortZone(stateContainerPlan.map[packageId].sortZone);
            getAisleAndShelf(stateContainerPlan.sortZoneMappings[stateContainerPlan.map[packageId].sortZone]);
        } else {
            updateAlert(navActions, "error", <FormattedMessage id={NOTIFICATION_STRING.ALERT_ERROR_SORTZONE_NOT_FOUND} values={{"package": packageId}}/>);
        }
    }

    const getAisleAndShelf = (sortZoneDestinations) => {
        for (let i = 0; i < sortZoneDestinations.length; i++) {
            if (sortZoneDestinations[i].exclusive === true) {
                setShelf(sortZoneDestinations[i].rack);
                setAisle(sortZoneDestinations[i].aisle);
            }
        }
    }

    const onLocationScan = (sortZoneScannableId) => {
        let destination = isValidDestination(sortZoneScannableId);
        if (destination !== false) {
            setView(false);
            setIsLoading(true);
            updateAlert(navActions, "success", <FormattedMessage id={NOTIFICATION_STRING.STOW_PACKAGE_SUCCESS} values={{"packageId": packageId}} />);
            uploadStowAction(packageId, sortZoneScannableId, sortZone, destination.label, stateContainerPlan.cycle, destination.exclusive, destination.stationCode).then(() => {
                setIsLoading(false);
                setPackageId("");
                setAisle("");
                setShelf("");
                setSortZone("");
            });
        } else {
            updateAlert(navActions, "error", <FormattedMessage id={NOTIFICATION_STRING.STOW_PACKAGE_ERROR} />);
            Logger.log.info("Invalid destination scanned while stowing: " + sortZoneScannableId + " for sortZone: " + sortZone);
        }
    }

    const onMessage = (event) => {
        try {
            const eventData = JSON.parse(event.data);
            Logger.log.info("EventData : " + JSON.stringify(eventData));
            if(eventData.eventName && eventData.eventName === MeshEvents.MESH_EVENTS.AVERY_SCAN
                && eventData.sessionId === SessionManager.getSessionId()) {
                if (view) {
                    onLocationScan(eventData.payload);
                } else {
                    onPackageScan(eventData.payload);
                }
            }
        } catch (JSONParseException) {
            Logger.log.warn("Enable to parse event : " + event.data);
        }
    };

    const isValidDestination = (sortZoneScannableId) => {
        let sortZoneDestinations = stateContainerPlan.sortZoneMappings[sortZone];
        for (let i = 0; i < sortZoneDestinations.length; i++) {
            if (sortZoneDestinations[i].scannableId === sortZoneScannableId) {
                return sortZoneDestinations[i];
            }
        }
        return false;
    };

    const clear = () => {
        updateAlert(navActions);
        dolphinBus.removeEventListener('message', onMessage);
    }

    useEffect(() => {
        navActions.resetNavCol();
        navActions.closeOnBack();
        dolphinBus.addEventListener('message', onMessage);
        if(onBackClicked) {
            navActions.onBackDefault();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            clear();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const HeadingView = () => {

        const getTitles = () => {
            return {
                primaryTitle: getBoldTitle("Scan location"),
                secondaryTitle: getNormalTitle("Place the package in location")
            };
        }

        const getBoldTitle = (title) => {
            return {
                title: title,
                type: "Bold",
                size: "Large"
            }
        }

        const getNormalTitle = (title) => {
            return {
                title: title
            }
        }

        return <HeaderView {...getTitles()}/>
    }

    const scanPackageView = () => {
        return (
            <Column height="100%" spacing="none" spacingInset="none">
                <Column height="100%" spacingInset="small">
                    <ScanWithCodeView
                        primaryTitle={{
                            title: "Scan package",
                            size: "Large",
                            type: "Bold"
                        }}
                        image={qrCodeImage}
                        onSubmit={onPackageScan}
                        focusLock={!helpOpen}
                    />
                </Column>
            </Column>
        );
    }

    const scanLocationView = () => {
        return (
            <Column spacingInset="small">
                <Box type="outline" spacingInset="small">
                    <Box spacingInset="small">
                        <HeadingView></HeadingView>
                        <Row>
                            <SnapshotView title={aisle} headerFontSize="Medium"
                                          description="Aisle"/>
                            <SnapshotView title={shelf} headerFontSize="Medium"
                                          description="Shelf"/>
                        </Row>
                        <SnapshotView title={packageId} headerFontSize="Medium"
                                      description="Package Id"/>
                    </Box>
                </Box>
                <ScanWithCodeView onSubmit={onLocationScan} focusLock={true}/>
            </Column>
        );
    }

    return (
        <div>
            {view ? scanLocationView() : scanPackageView()}
        </div>
    );
}

export default injectIntl(StowPackageView);