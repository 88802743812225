import {DataHandler, Logger} from "@amzn/dolphin-web-framework";
import {syncTablesToS3, uploadToS3} from "../../network/S3Utils";
import { DELIMITER, OBJECT_STORE, S3_PREFIX, TABLE} from "../../../constants/constants";
import {loadConfig} from "../../home/network/Loader";
import AmazonS3URI from "amazon-s3-uri";
import {getS3Client} from "../../../utils/S3Client";
import {
    getBucketNameForRouteAssignment,
    getBucketNameForRouteInfo,
    getBucketNameForRoutePickComplete
} from "../../../utils/network/BaseURLUtils";
import {clearDbByName, getObjectByIdFromIndexedDB, saveInformationInDB} from "../utils/pickDbUtils";
import {
    EXCEPTION,
    ROUTE_ALREADY_COMPLETED,
    ROUTE_ASSIGNED_TO_OTHER,
    UNABLE_TO_ASSIGN_ROUTE, UNABLE_TO_FETCH_ROUTE_ASSIGNMENT,
    UNABLE_TO_FETCH_ROUTE_INFO
} from "../pickConstants";

/**
 * Retrieves the Pick Context from indexed db
 * @returns {Promise<Object>}
 */
export const getPickContextFromIndexedDB = () => {
    return getObjectByIdFromIndexedDB(TABLE.PICK_CONTEXT, OBJECT_STORE.PICK_CONTEXT, OBJECT_STORE.PICK_CONTEXT, OBJECT_STORE.PICK_CONTEXT);
}

/**
 * This method assign a route to the associate, following are the steps
 * Step 1: Check if the given route is assigned already if not assigned then fetch the route details and assign the route to user else step 2
 * Step 2: if route is not assigned to the current user then return else step 3
 * Step 3: check if the route is already picked if picked already then return else step 4
 * Step 4: fetch the route details from S3
 * @param routeCode:{String} code for the route to be picked
 * @returns {Promise<Object>}
 */
export const assignRoute = (routeCode) => {
    return new Promise((resolve, reject) => {
        const userEmployeeId = DataHandler.getUserEmployeeId()
        const stationCode = DataHandler.getStationCode();
        const uri = S3_PREFIX + getBucketNameForRouteAssignment() + DELIMITER + getShortDate() + DELIMITER + stationCode + DELIMITER + routeCode;
        const s3Client = getS3Client();
        const URI = AmazonS3URI(uri);
        loadConfig(s3Client, URI.bucket, URI.key).then((response) => {
            try {
                const data = response.Body.toString('utf-8');
                const parsedData = JSON.parse(data);
                if (parsedData && parsedData["routeAssignedTo"] !== userEmployeeId) {
                    reject(ROUTE_ASSIGNED_TO_OTHER);
                } else {
                    checkRouteAlreadyCompleted(routeCode).then(exist => {
                        if(exist) {
                            reject(ROUTE_ALREADY_COMPLETED);
                        } else {
                            fetchRouteDetailsFromS3(routeCode).then(routeDetails => {
                                resolve(routeDetails)
                            }).catch(err => {
                                Logger.log.error(`Error occurred while fetching route details for route: ${routeCode}, stationCode: ${stationCode}`, err);
                                reject(EXCEPTION);
                            });
                        }
                    });
                }
            } catch (err) {
                Logger.log.error("Error while parsing response", err);
                reject(EXCEPTION);
            }
        }).catch(err => {
            if(err && err.code === "NoSuchKey") {
                fetchRouteDetailsFromS3(routeCode).then(routeDetails => {
                    assignRouteToAssociate(routeCode, routeDetails.routeId).then(() => {
                        resolve(routeDetails);
                    }).catch(err => {
                        Logger.log.error(`Error while assigning route: ${routeCode} to user: ${userEmployeeId}`)
                        reject(UNABLE_TO_ASSIGN_ROUTE);
                    });
                }).catch(err => {
                    Logger.log.error(`Error occurred while fetching the route info for route: ${routeCode}`);
                    reject(UNABLE_TO_FETCH_ROUTE_INFO);
                });
            } else {
                Logger.log.error(`Error while fetching ${URI.bucket}:${URI.key}`, err);
                reject(UNABLE_TO_FETCH_ROUTE_ASSIGNMENT);
            }
        });
    })
}

const fetchRouteDetailsFromS3 = (routeCode) => {
    return new Promise( (resolve, reject) => {
        const stationCode = DataHandler.getStationCode();
        const uri = S3_PREFIX + getBucketNameForRouteInfo() + DELIMITER + stationCode + DELIMITER + getShortDate() + DELIMITER + routeCode + "_route_info.json";
        const s3Client = getS3Client();
        const URI = AmazonS3URI(uri);
        loadConfig(s3Client, URI.bucket, URI.key).then(response => {
            try {
                const data = response.Body.toString('utf-8');
                const parsedData = JSON.parse(data);
                resolve(parsedData);
            } catch (err) {
                Logger.log.info("Error occurred while parsing response from S3");
                reject();
            }
        }).catch(err => {
            Logger.log.error(`Error occurred while fetching route info for route code: ${routeCode}, Error: ${err}`);
            reject();
        })
    })
}

const checkRouteAlreadyCompleted = (routeCode) => {
    return new Promise((resolve, reject) => {
        const stationCode = DataHandler.getStationCode();
        const uri = S3_PREFIX + getBucketNameForRoutePickComplete() + DELIMITER + getShortDate() + DELIMITER + stationCode + DELIMITER + routeCode;
        const s3Client = getS3Client();
        const URI = AmazonS3URI(uri);
        loadConfig(s3Client, URI.bucket, URI.key).then(response => {
            const data = response.Body.toString('utf-8');
            if(data) {
                resolve(true);
            }
        }).catch(err => {
            if(err && err.code === "NoSuchKey") {
                resolve(false);
            } else {
                reject();
            }
        })
    })
}

const assignRouteToAssociate = (routeCode, routeId) => {
    return new Promise((resolve, reject) => {
       const stationCode = DataHandler.getStationCode();
       const uri = S3_PREFIX + getBucketNameForRouteAssignment() + DELIMITER + getShortDate() + DELIMITER + stationCode + DELIMITER + routeCode;
       const userEmployeeId = DataHandler.getUserEmployeeId();
       const URI = AmazonS3URI(uri);
       const object = {
           routeAssignedTo: userEmployeeId,
           assignedAt: Date.now(),
           routeId
       }
       uploadToS3(URI.bucket, URI.key, JSON.stringify(object)).then(() => {
           resolve();
       }).catch(err => {
           Logger.log.error(`Error occurred while uploading file to s3`, err);
           reject();
       });
    });
}

const getShortDate = () => {
    const today = new Date();
    return today.getFullYear() + ":" + ("0" + (today.getMonth() + 1)).slice(-2) + ":" + ("0" + today.getDate()).slice(-2);
}

/**
 * Delete the pick execution logs indexed db
 */
export const deletePickDB = () => {
    clearDbByName(TABLE.PICK_ACTION);
}

/**
 * Uploads the logs from pick execution db to S3
 * @param routeCode:{String}
 * @returns {Promise<>}
 */
export const uploadLogsToS3 = (routeCode) => {
    const fileName = getShortDate() + DELIMITER + DataHandler.getStationCode() + DELIMITER + routeCode;
    return syncTablesToS3(TABLE.PICK_ACTION, OBJECT_STORE.PICK_ACTION, getBucketNameForRoutePickComplete(), fileName);
}

/**
 * Saves the log in pick execution log db
 * @param operation
 * @param params
 */
export const saveAction = (operation, params) => {
    const object = {
        value: [...getCommonDetails(operation), ...params].join('~')
    }
    saveInformationInDB(TABLE.PICK_ACTION, OBJECT_STORE.PICK_ACTION, null, object, operation);
}

const getCommonDetails = (operation) => {
    const today = Date.now();
    const stationCode = DataHandler.getStationCode();
    const userId = DataHandler.getUserId();
    const userEmployeeId = DataHandler.getUserEmployeeId();
    const userEmployeeLogin = DataHandler.getUserEmployeeLogin();
    return [today, operation, stationCode, userId, userEmployeeId, userEmployeeLogin]
}
