import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import {PickingContext} from "./context/pickingContext";
import {AppNavigationContext} from "../appNavigation/context/AppNavigationContext";
import InputRoute from "./inputRouteView";
import RouteDetailsView from "./routeDetailsView";
import {updateAlert} from "../../utils/utils";
import {PICKING_STATUS} from "./reducer/PickingContextReducer";

const PrePick = (props) => {
    const {navActions} = useContext(AppNavigationContext);
    const {pickingState, pickingActions} = useContext(PickingContext);

    const startPicking = () => {
        pickingActions.setStatus(PICKING_STATUS.PICKING);
        updateAlert(navActions);
    }

    return (
        pickingState.routeCode ? <RouteDetailsView onContinueClicked={startPicking} /> : <InputRoute />
    );

}

export default injectIntl(PrePick);
