import {DataHandler} from "@amzn/dolphin-web-framework";
import {saveInformationInDB} from "../../network/IndexedDbUtility";
import {OBJECT_STORE, OPERATION_INDUCT, TABLE} from "../../../constants/constants";

const upload = async (packageId, status, sortZone, cycle, stationCode, parentStationCode) => {
    const today = new Date();
    const executionStationCode = DataHandler.getStationCode();
    const userId = DataHandler.getUserId();
    const userEmployeeId = DataHandler.getUserEmployeeId();
    const userLogin = DataHandler.getUserEmployeeLogin();
    const cycleInformation = (cycle == null) ? "CYCLE_INFORMATION_NOT_FOUND" : cycle;
    const value = [today.getTime(), OPERATION_INDUCT, status, cycleInformation, executionStationCode, stationCode,
        parentStationCode, userId, userEmployeeId, userLogin, packageId, sortZone].join('~');

    const object = {
        "packageId": packageId,
        "value": value
    }

    saveInformationInDB(TABLE.INDUCT, OBJECT_STORE.INDUCT, OBJECT_STORE.INDUCT, object, OPERATION_INDUCT);
}

const getSALOutput = (sortZone, packageID) => {
    let output;
    if(sortZone === 'C-Plan') {
        output = sortZone + '||' + packageID + '||||DRIVER|AID|';
    } else {
        output = sortZone + '||' + packageID + '||||DRIVER|AID|';
    }
    return output;
}

export {upload, getSALOutput};