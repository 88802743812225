import {Constants} from "@amzn/dolphin-web-framework";
import {
    BUCKET_BETA, BUCKET_BETA_CLOSE_BAG,
    BUCKET_BETA_CP, BUCKET_BETA_OPEN_BAG, BUCKET_BETA_STOW,
    BUCKET_GAMMA_EU, BUCKET_GAMMA_EU_CLOSE_BAG,
    BUCKET_GAMMA_EU_CP, BUCKET_GAMMA_EU_OPEN_BAG, BUCKET_GAMMA_EU_STOW,
    BUCKET_GAMMA_FE, BUCKET_GAMMA_FE_CLOSE_BAG,
    BUCKET_GAMMA_FE_CP, BUCKET_GAMMA_FE_OPEN_BAG, BUCKET_GAMMA_FE_STOW,
    BUCKET_GAMMA_NA, BUCKET_GAMMA_NA_CLOSE_BAG,
    BUCKET_GAMMA_NA_CP, BUCKET_GAMMA_NA_OPEN_BAG, BUCKET_GAMMA_NA_STOW,
    BUCKET_PROD_EU, BUCKET_PROD_EU_CLOSE_BAG,
    BUCKET_PROD_EU_CP, BUCKET_PROD_EU_OPEN_BAG, BUCKET_PROD_EU_STOW,
    BUCKET_PROD_FE, BUCKET_PROD_FE_CLOSE_BAG, BUCKET_PROD_FE_CP, BUCKET_PROD_FE_OPEN_BAG, BUCKET_PROD_FE_STOW,
    BUCKET_PROD_NA, BUCKET_PROD_NA_CLOSE_BAG,
    BUCKET_PROD_NA_CP, BUCKET_PROD_NA_OPEN_BAG, BUCKET_PROD_NA_STOW, DATE_OFFSET,
    BUCKET_BETA_ROUTE_ASSIGNMENT, BUCKET_GAMMA_NA_ROUTE_ASSIGNMENT,
    BUCKET_GAMMA_EU_ROUTE_ASSIGNMENT, BUCKET_GAMMA_FE_ROUTE_ASSIGNMENT,
    BUCKET_PROD_NA_ROUTE_ASSIGNMENT, BUCKET_PROD_EU_ROUTE_ASSIGNMENT, BUCKET_PROD_FE_ROUTE_ASSIGNMENT,
    BUCKET_GAMMA_NA_ROUTE_INFO, BUCKET_BETA_ROUTE_INFO, BUCKET_PROD_NA_ROUTE_INFO, BUCKET_PROD_EU_ROUTE_INFO,
    BUCKET_PROD_FE_ROUTE_INFO, BUCKET_GAMMA_FE_ROUTE_INFO, BUCKET_GAMMA_EU_ROUTE_INFO,
    BUCKET_PROD_EU_ROUTE_PICK_COMPLETE, BUCKET_PROD_FE_ROUTE_PICK_COMPLETE, BUCKET_GAMMA_NA_ROUTE_PICK_COMPLETE,
    BUCKET_BETA_ROUTE_PICK_COMPLETE, BUCKET_GAMMA_EU_ROUTE_PICK_COMPLETE, BUCKET_GAMMA_FE_ROUTE_PICK_COMPLETE,
    BUCKET_PROD_NA_ROUTE_PICK_COMPLETE,

} from "../../constants/constants";

export function getRegion() {
    const hostname = window.location.hostname;
    if (hostname.startsWith('na')) return Constants.Region.REGION_NA;
    else if (hostname.startsWith('eu')) return Constants.Region.REGION_EU;
    else if (hostname.startsWith('fe')) return Constants.Region.REGION_FE;
    return Constants.Region.REGION_NA;
}

export function getStage() {
    const hostname = window.location.hostname.split('.');
    if (hostname[0] != null && hostname[0].startsWith(Constants.Stage.BETA))
        return Constants.Stage.BETA;
    else if (hostname[1] != null && hostname[1].startsWith(Constants.Stage.GAMMA))
        return Constants.Stage.GAMMA;
    else if (hostname[1] != null && hostname[1].startsWith(Constants.Stage.PROD))
        return Constants.Stage.PROD;
    return Constants.Stage.GAMMA;
}

export function getIdentityPoolId() {
    switch(getStage()) {
        case Constants.Stage.BETA: return "us-west-2:e56c9992-30f3-4555-b72b-153a156f6d2b";
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "us-east-1:339564f2-478a-4d8f-b61c-45eb357e1419";
                case Constants.Region.REGION_EU: return "eu-west-1:19759e64-7b3b-46da-b1db-5814ef7e4686";
                case Constants.Region.REGION_FE: return "us-west-2:aa5b2108-9c22-46dd-b695-158eeb50297a";
                // no default
            }
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "us-east-1:0e7288be-17ff-47cd-90d2-528f52768e6c";
                case Constants.Region.REGION_EU: return "eu-west-1:fd9a9aff-537b-45b0-951b-76ce933889d1";
                case Constants.Region.REGION_FE: return "us-west-2:a80aedac-374b-475b-989d-dc477922c55a";
                // no default
            }
            break;
        // no default
    }
}

export function getIdentityPoolIdRegion() {
    switch(getStage()) {
        case Constants.Stage.BETA: return "us-west-2";
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "us-east-1";
                case Constants.Region.REGION_EU: return "eu-west-1";
                case Constants.Region.REGION_FE: return "us-west-2";
                // no default
            }
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "us-east-1";
                case Constants.Region.REGION_EU: return "eu-west-1";
                case Constants.Region.REGION_FE: return "us-west-2";
                // no default
            }
            break;
        // no default
    }
}

export function getBucketName() {
    switch(getStage()) {
        case Constants.Stage.BETA: return BUCKET_BETA;
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_GAMMA_NA;
                case Constants.Region.REGION_EU: return BUCKET_GAMMA_EU;
                case Constants.Region.REGION_FE: return BUCKET_GAMMA_FE;
                // no default
            }
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_PROD_NA;
                case Constants.Region.REGION_EU: return BUCKET_PROD_EU;
                case Constants.Region.REGION_FE: return BUCKET_PROD_FE;
                // no default
            }
            break;
        // no default
    }
}

export function getBucketNameForCP() {
    switch(getStage()) {
        case Constants.Stage.BETA: return BUCKET_BETA_CP;
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_GAMMA_NA_CP;
                case Constants.Region.REGION_EU: return BUCKET_GAMMA_EU_CP;
                case Constants.Region.REGION_FE: return BUCKET_GAMMA_FE_CP;
                // no default
            }
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_PROD_NA_CP;
                case Constants.Region.REGION_EU: return BUCKET_PROD_EU_CP;
                case Constants.Region.REGION_FE: return BUCKET_PROD_FE_CP;
                // no default
            }
            break;
        // no default
    }
}

export function getBucketNameForOB() {
    switch(getStage()) {
        case Constants.Stage.BETA: return BUCKET_BETA_OPEN_BAG;
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_GAMMA_NA_OPEN_BAG;
                case Constants.Region.REGION_EU: return BUCKET_GAMMA_EU_OPEN_BAG;
                case Constants.Region.REGION_FE: return BUCKET_GAMMA_FE_OPEN_BAG;
                // no default
            }
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_PROD_NA_OPEN_BAG;
                case Constants.Region.REGION_EU: return BUCKET_PROD_EU_OPEN_BAG;
                case Constants.Region.REGION_FE: return BUCKET_PROD_FE_OPEN_BAG;
                // no default
            }
            break;
        // no default
    }
}

export function getBucketNameForCB() {
    switch(getStage()) {
        case Constants.Stage.BETA: return BUCKET_BETA_CLOSE_BAG;
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_GAMMA_NA_CLOSE_BAG;
                case Constants.Region.REGION_EU: return BUCKET_GAMMA_EU_CLOSE_BAG;
                case Constants.Region.REGION_FE: return BUCKET_GAMMA_FE_CLOSE_BAG;
                // no default
            }
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_PROD_NA_CLOSE_BAG;
                case Constants.Region.REGION_EU: return BUCKET_PROD_EU_CLOSE_BAG;
                case Constants.Region.REGION_FE: return BUCKET_PROD_FE_CLOSE_BAG;
                // no default
            }
            break;
        // no default
    }
}

export function getBucketNameForStow() {
    switch(getStage()) {
        case Constants.Stage.BETA: return BUCKET_BETA_STOW;
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_GAMMA_NA_STOW;
                case Constants.Region.REGION_EU: return BUCKET_GAMMA_EU_STOW;
                case Constants.Region.REGION_FE: return BUCKET_GAMMA_FE_STOW;
                // no default
            }
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_PROD_NA_STOW;
                case Constants.Region.REGION_EU: return BUCKET_PROD_EU_STOW;
                case Constants.Region.REGION_FE: return BUCKET_PROD_FE_STOW;
                // no default
            }
            break;
        // no default
    }
}

export function getBucketNameForRouteAssignment() {
    switch(getStage()) {
        case Constants.Stage.BETA: return BUCKET_BETA_ROUTE_ASSIGNMENT;
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_GAMMA_NA_ROUTE_ASSIGNMENT;
                case Constants.Region.REGION_EU: return BUCKET_GAMMA_EU_ROUTE_ASSIGNMENT;
                case Constants.Region.REGION_FE: return BUCKET_GAMMA_FE_ROUTE_ASSIGNMENT;
                // no default
            }
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_PROD_NA_ROUTE_ASSIGNMENT;
                case Constants.Region.REGION_EU: return BUCKET_PROD_EU_ROUTE_ASSIGNMENT;
                case Constants.Region.REGION_FE: return BUCKET_PROD_FE_ROUTE_ASSIGNMENT;
                // no default
            }
            break;
        // no default
    }
}

export function getBucketNameForRouteInfo() {
    switch(getStage()) {
        case Constants.Stage.BETA: return BUCKET_BETA_ROUTE_INFO;
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_GAMMA_NA_ROUTE_INFO;
                case Constants.Region.REGION_EU: return BUCKET_GAMMA_EU_ROUTE_INFO;
                case Constants.Region.REGION_FE: return BUCKET_GAMMA_FE_ROUTE_INFO;
                // no default
            }
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_PROD_NA_ROUTE_INFO;
                case Constants.Region.REGION_EU: return BUCKET_PROD_EU_ROUTE_INFO;
                case Constants.Region.REGION_FE: return BUCKET_PROD_FE_ROUTE_INFO;
                // no default
            }
            break;
        // no default
    }
}

export function getBucketNameForRoutePickComplete() {
    switch(getStage()) {
        case Constants.Stage.BETA: return BUCKET_BETA_ROUTE_PICK_COMPLETE;
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_GAMMA_NA_ROUTE_PICK_COMPLETE;
                case Constants.Region.REGION_EU: return BUCKET_GAMMA_EU_ROUTE_PICK_COMPLETE;
                case Constants.Region.REGION_FE: return BUCKET_GAMMA_FE_ROUTE_PICK_COMPLETE;
                // no default
            }
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return BUCKET_PROD_NA_ROUTE_PICK_COMPLETE;
                case Constants.Region.REGION_EU: return BUCKET_PROD_EU_ROUTE_PICK_COMPLETE;
                case Constants.Region.REGION_FE: return BUCKET_PROD_FE_ROUTE_PICK_COMPLETE;
                // no default
            }
            break;
        // no default
    }
}

export function getLoggerUrl() {
    switch(getStage()) {
        case Constants.Stage.BETA:
            return "https://pefb52glwc.execute-api.us-west-2.amazonaws.com/prod/v1/log";
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "https://cfx0sv6f1h.execute-api.us-east-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_EU: return "https://tgn56lzdl5.execute-api.eu-west-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_FE: return "https://qbac8upns2.execute-api.us-west-2.amazonaws.com/prod/v1/log";
                // no default
            }
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "https://3k235mghve.execute-api.us-east-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_EU: return "https://brq6iffv2h.execute-api.eu-west-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_FE: return "https://lzpit6ht46.execute-api.us-west-2.amazonaws.com/prod/v1/log";
                // no default
            }
            break;
        // no default
    }
}

function getMonth(date) {
    const month = date.getUTCMonth() + 1;
    const formattedMonth = (month <= 9) ? ("0" + month.toString()) : month.toString();
    return formattedMonth;
}

function getDay(date) {
    const day = date.getUTCDate();
    const formattedDay = (day <= 9) ? ("0" + day.toString()) : day.toString();
    return formattedDay;
}

export function getDates() {
    const currentDate = new Date();
    let year = currentDate.getUTCFullYear().toString();
    let month = getMonth(currentDate);
    let day = getDay(currentDate);
    let today = year + "-" + month + "-" + day;

    const prevDate = new Date(currentDate.getTime() - DATE_OFFSET);
    year = prevDate.getUTCFullYear().toString();
    month = getMonth(prevDate);
    day = getDay(prevDate);
    let yesterday = year + "-" + month + "-" + day;

    const nextDate = new Date(currentDate.getTime() + DATE_OFFSET);
    year = nextDate.getUTCFullYear().toString();
    month = getMonth(nextDate);
    day = getDay(nextDate);
    let tomorrow = year + "-" + month + "-" + day;

    //Check 3 buckets in order of tomorrow, today, yesterday.
    return [tomorrow, today, yesterday];
}
