export const AppNavigationActions = {
    SETUP_HELP: "SETUP_HELP",
    OPEN_HELP: "OPEN_HELP",
    CLOSE_HELP: "CLOSE_HELP",
    ERROR_COL: "ERROR_COL",
    RESET_COL: "RESET_COL",
    SET_LOADER: "SET_LOADER",
    CLOSE_ON_BACK: "CLOSE_ON_BACK",
    CALLBACK_ON_BACK: "CALLBACK_ON_BACK",
    SHOW_ALERT: "SHOW_ALERT",
    SET_NAVIGATION_TITLE: "SET_NAVIGATION_TITLE",
    CLEAR_ALERT: "CLEAR_ALERT",
    INIT_CONTEXT: "INIT_CONTEXT",
    SET_HELP_OPTION: "SET_HELP_OPTION",
    OPEN_HELP_OPTION: "OPEN_HELP_OPTION",
    CLOSE_HELP_OPTION: "CLOSE_HELP_OPTION",
    CLOSE_BACK_DROP: "CLOSE_BACK_DROP",
    OPEN_CUSTOM_HELP_OPTION: "OPEN_CUSTOM_HELP_OPTION",
    CLOSE_CUSTOM_HELP_OPTION: "CLOSE_CUSTOM_HELP_OPTION",
    CLOSE_BACK_ENABLED: "CLOSE_BACK_ENABLED",
    OPEN_BACK_ENABLED: "OPEN_BACK_ENABLED"
};