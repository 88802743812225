const cacheContainerPlan = (containerPlanList) => {
    let map = {};
    for(let i = 0; i < containerPlanList.length; i++) {
        const packageDetails = JSON.parse(containerPlanList[i].containerPlan).containerAssignments;

        packageDetails.forEach(cluster => {
            cluster.shipments.forEach(packageId => {
                map[packageId.trackingId] = {sortZone: cluster.sortZoneName, parentStationCode: containerPlanList[i].parentStationCode,
                    stationCode: containerPlanList[i].stationCode};
            });
        });
    }

    return map;
}

const cacheSortZoneMappings = (sortZoneMappings) => {
    let map = {};
    for(let i = 0; i < sortZoneMappings.length; i++) {
        const sortZoneDetails = JSON.parse(sortZoneMappings[i].sortZoneMapping);
        const stationCode = sortZoneMappings[i].stationCode;
        for (let key in sortZoneDetails) {
            if ("stationCode" === key) {
                continue;
            }
            map[key] = [];
            for (let j = 0; j < sortZoneDetails[key].length; j++) {
                let container = sortZoneDetails[key][j];
                map[key].push({
                    aisle: container.aisle,
                    rack: container.rack,
                    exclusive: container.exclusive,
                    scannableId: container.scannableId,
                    stationCode: stationCode,
                    label: container.label
                });
            }
        }
    }

    return map;
}

export {cacheContainerPlan, cacheSortZoneMappings};